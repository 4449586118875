import React, { useState, useEffect } from "react";
import {
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Stack from "@mui/material/Stack";

export function ShowIn(props) {
  const prop_path_store =
    "display_on_pos";
  const prop_path_catlog =
    "display_on_store";

  const valueMap = {
    "Punto de venta": props.article ? props.article[prop_path_store] : [],
    "Tienda en línea / Catálogo": props.article
      ? props.article[prop_path_catlog]
      : [],
  };

  const handleCheckboxChange = (data) => {
    if (data === "Tienda en línea / Catálogo") {
      props.SetProductInfo({
        [prop_path_catlog]: valueMap[data] ? false : true,
      });
    } else if (data === "Punto de venta") {
      props.SetProductInfo({
        [prop_path_store]: valueMap[data] ? false : true,
      });
    }
  };

  return (
    <>
      {["Tienda en línea / Catálogo", "Punto de venta"].map((data, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems={"center"}
          spacing={props.spacing}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => handleCheckboxChange(data)}
                color="primary"
                checked={valueMap[data] ? true : false}
              />
            }
          />
          <Typography>{data}</Typography>
        </Stack>
      ))}
    </>
  );
}