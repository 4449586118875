import React, { useState } from "react";
import BasicModal from "../../../components/GenericModal";
import { TextField, Grid, Box, IconButton, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValAddSupplier, valSchAddSupplier } from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import CustomerService from "../../../api/customerService";
import CountWords from "../../../components/CountWords";
import { useNavigate } from "react-router-dom";
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import { GenericDrawer } from "../../../components/keskiCreator/genericDrawer";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";



const AddCustomer = (props) => {

    const [loading, setLoading] = useState(false);
    const { logout, auth } = useAuth();
    const navigate = useNavigate();
    const [msg, sendNotification] = useNotification();

    const formik = useFormik({
        initialValues: initValAddSupplier(auth.user.pk),
        validationSchema: Yup.object(valSchAddSupplier()),
        onSubmit: async (formData) => {
            setLoading(true);
            let customerService = new CustomerService();
            let response = await customerService.postDataAuth(formData, logout);
            setLoading(false);
            if (Number(response.status) < 300) {
                props.close();
                window.localStorage.setItem("menu", 22);
                navigate(`detail/${response.data.id}`);
            }
            sendNotification(UseerrorOrsuccess(response))
            props.UpdateSupplierList();
            setLoading(false);
        },
    });


    return (
        <>
            <GenericDrawer
                open={props.open}
                toggleDrawer={props.toggleDrawer}
                anchor={"right"}
                drawerWidth="30%"
            >
                <Box p={'16px'}>
                    <Grid
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography fontSize={"20px"} p={"8px 0"}>
                            <b>Agregar ajustes</b>
                        </Typography>

                        <IconButton onClick={props.close}>
                            <CloseSharpIcon fontSize="small" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} pt={'24px'}>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        inputProps={{ maxLength: 100 }}
                                        fullWidth
                                        name="business_name"
                                        values={formik.values.business_name}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.business_name && Boolean(formik.errors.business_name)
                                        }
                                        label="Nombre del cliente"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        autoComplete='none'
                                        helperText={
                                            formik.touched.name ? formik.errors.name : ""
                                        }
                                    />
                                    <CountWords length={formik.values.business_name.length} max="100" />
                                    <TextField
                                        inputProps={{ maxLength: 13 }}
                                        fullWidth
                                        name="rfc"
                                        values={formik.values.rfc}
                                        onChange={formik.handleChange}

                                        error={
                                            formik.touched.rfc && Boolean(formik.errors.rfc)
                                        }
                                        label="RFC"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        autoComplete='none'
                                        helperText={
                                            formik.touched.rfc ? formik.errors.rfc : ""
                                        }
                                    />
                                    <CountWords length={formik.values.rfc.length} max="20" />
                                    <TextField
                                        inputProps={{ maxLength: 10 }}
                                        fullWidth
                                        name="phone"
                                        values={formik.values.phone}
                                        onChange={formik.handleChange}
                                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                                        label="Teléfono"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        autoComplete='none'
                                        helperText={formik.touched.phone ? formik.errors.phone : ""}
                                    />
                                    <CountWords length={formik.values.phone.length} max="10" />
                                    <TextField
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        name="email"
                                        values={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.password1 && Boolean(formik.errors.email)
                                        }
                                        label="Correo Electrónico"
                                        margin="dense"
                                        variant="outlined"
                                        autoComplete='none'
                                        size="small"
                                        helperText={
                                            formik.touched.email ? formik.errors.email : ""
                                        }
                                    />
                                    <CountWords length={formik.values.email.length} />
                                </Grid>
                            </Grid>

                            <Grid container justifyContent="flex-end">
                                <Grid
                                    item
                                    xs={12}
                                    sm={7}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    sx={{ marginTop: "15px" }}
                                >
                                    <LoadingButton
                                        loading={loading}

                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        sx={{ color: "#fff", borderRadius: 60 }}
                                    >
                                        Agregar
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Box>
            </GenericDrawer>


        </>
    );
}

export default AddCustomer;