import {
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import StoreOrdersManages from "../../api/store/storeOrders";
import BasicTable from "../../components/GenericTable";
import Container from "../../layout/gridContainer";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { STATUSSTOREORDER, STATUSSTOREORDERCOLOR } from "../../utils/constants";
import { GenericEmptyTable, GenericSkeletonTable } from "../../components/GenericEmptyTable";
import { useTableFilters } from "../../hooks/searchParams";
import PageParam from "../../components/PageParam";
import { formatMoney } from "../../utils/functions";
import { DateFormat } from "../../components/DateFormat";

export const StoreOrders = () => {
  const [table, setTable] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let filter = [];
  filter.push({ key: "status_exclude", value: `1-2-8-9` });
  const [params, setParams, handleSearch, handleSearchEmpty, UdateTable] =
    useTableFilters(StoreOrdersManages, setTable, setLoading, filter);

  return (
    <>
      <Container alignItems="center">
        <Grid container>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            mb="16px"
          >
            <Grid item xs={12} sm={6}>
              <Grid container fontWeight="bold">
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    component="div"
                    className="title-card"
                    sx={{ paddingBottom: "2%" }}
                  >
                    <strong>Pedidos</strong>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
            </Grid>
          </Grid>
        </Grid>


        <>
          {" "}
          <BasicTable
            titles={[
              "N. Orden / Fecha  ",
              "Nombre del cliente",
              "Estatus",
              "Artículos",
              "Importe",
            ]}
          >
            {table?.data?.length > 0 &&
              table.data.map((data, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  hover
                  onDoubleClick={() => {
                    window.localStorage.setItem("menu", 25);
                    navigate(`detail/${data.id}`);
                  }}
                >
                  <TableCell
                    align="left"
                    sx={{ minWidth: "20px", maxWidth: "15vw" }}
                  >
                    <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        component="div"
                        sx={{
                          minWidth: "20px",
                          maxWidth: "18vw",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data.id}
                      </Typography>
                      <DateFormat beginDate={data.date_created} />
                    </Typography>
                  </TableCell>

                  <TableCell sx={{ maxWidth: "250px" }} align="left">
                    <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{
                        minWidth: "20px",
                        maxWidth: "18vw",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data.delivery_address?.recipient_full_name}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Chip
                        label={STATUSSTOREORDER[data.status]}
                        color={STATUSSTOREORDERCOLOR[data.status]}
                        className="chip-with"
                        variant="outlined"
                      />
                    </Typography>
                  </TableCell>

                  <TableCell sx={{ maxWidth: "250px" }} align="left">
                    <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{
                        minWidth: "20px",
                        maxWidth: "18vw",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.cart?.total_items}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {formatMoney(data.total)}

                      <Typography
                        variant="subtitle2"
                        component="div"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontWeight: "bold",
                        }}
                      >
                        {data?.status === 7 &&
                          data?.cancel?.status === 2 &&
                          " (reembolsado)"}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        {(data?.status === 3 || data?.status === 4) &&
                          data?.cancel?.status === 1 &&
                          " (sin reembolsar)"}
                      </Typography>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            }
            {loading && !table && (
              <GenericSkeletonTable />
            )}
            {!table && !loading && <GenericEmptyTable />}
          </BasicTable>
          <PageParam
            totalpages={table?.pagination || 1}
            params={params}
            handleChange={handleSearch}
          />
        </>
      </Container>
    </>
  );
};
