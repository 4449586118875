import React, { useState, useEffect } from "react";
import { Suppliers } from "../../api/supplierService";
import CustomerService from "../../api/customerService";
import SalesService from "../../api/salesService";
import PurchaseService from "../../api/purchaseService";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import EmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import PlaceIcon from "@mui/icons-material/PlaceOutlined";
import CopyIcon from "@mui/icons-material/FileCopyOutlined";
import { GenericDrawer } from "../keskiCreator/genericDrawer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import CommentIcon from '@mui/icons-material/Comment';
import { useAuth } from "user-context-fran-dev";

export function DrawerSupplier(props) {

  const [data, setData] = useState("");
  const { logout } = useAuth();
  const [open, setOpen] = useState(false);
  const iconList = [PhoneIcon, EmailIcon];
  const prop_path = ["phone", "email"];
  const credit_prop_path = ["credit_days", "credit_limit", "discount"];
  const address_prop_path = ["street", 'ext_number', "suburb", , "postal_code", "state",];
  const credit_label = ["Días de crédito:", "Descuento:", "Límite de crédito:"];
  const navigate = useNavigate();

  useEffect(() => {
    if (props.id) {
      fetchData();
    }
  }, [props.open]);

  const fetchData = () => {
    (async () => {
      const services = {
        supplier: new Suppliers(),
        customer: new CustomerService(),
        purches: new PurchaseService(),
        sales: new SalesService()
      };
      const service = services[props.type];
      let response = await service.getAllByIdAuth(props.id, logout);
      setData(response?.data);
    })();
  };

  const fetchDataDelete = () => {
    (async () => {
      const services = {
        supplier: new Suppliers(),
        customer: new CustomerService(),
        purches: new PurchaseService(),
        sales: new SalesService()
      };
      const service = services[props.type];
      let response = await service.editDataAuth(props.id, { is_archived: true }, props.logout);
      props.close();
      if (props.UpdateInfo) {
        props.UpdateInfo()
      }
    })();
  };

  const paths = prop_path.map((val, index) => ({
    path: val,
    icon: iconList[index], // Set value to the corresponding key
  }));

  const credit_paths = credit_label.map((val, index) => ({
    label: val,
    path: credit_prop_path[index], // Set value to the corresponding key
  }));

  const redirectToDetailPage = () => {
    const menuMap = {
      supplier: 18,
      customer: 22,
      sales: 19,
      purches: 11
    };

    window.localStorage.setItem("menu", menuMap[props.type] || 18);
    navigate(`detail/${props.id}`);
  };

  const handleClick = () => {
    setOpen(true); // Muestra la alerta
  };

  const handleClose = () => {
    setOpen(false); // Cierra la alerta
  };

  return (
    <GenericDrawer
      open={props.open}
      toggleDrawer={props.toggleDrawer}
      anchor={"right2"}
      drawerWidth="30%"
    >
      <Box p={"16px"}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "16px",
          }}
        >
          <Typography fontSize={"20px"} p={"8px 0"}>
            <b>Vista previa</b>
          </Typography>

          <IconButton onClick={props.close}>
            <CloseSharpIcon fontSize="small" />
          </IconButton>
        </Grid>

        {data && (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={'center'}
            >
              <Typography variant="h5">
                <b>{data.business_name ?? data.no_order}</b>
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  pointerEvents: "none",
                  borderRadius: "60px",
                  color: "GrayText",
                  height: "32px",
                  textTransform: "none",
                  width: "110px",
                }}
              >
                {props.type !== "purches" && props.type !== "sales"
                  ? (data.type === 1 ? 'Empresa' : 'Persona')
                  : (data.status === 1 ? 'Borrador'
                    : data.status === 2 ? 'Confirmado'
                      : data.status === 3 ? 'Completado'
                        : 'Cancelado')}
              </Button>
            </Stack>

            <Typography sx={{ textTransform: "uppercase", padding: "8px 0" }}>
              {data.rfc ?? data?.warehouse?.name ?? ""}
            </Typography>

            <Divider sx={{ m: "16px 0" }} />

            {paths.map(({ path, icon: Icon }, index) => (
              <Grid
                display="flex"
                key={index}
                padding="8px 0"
                justifyContent="space-between"
              >
                <Grid display="flex">
                  <Icon style={{ marginRight: "10px" }} />
                  <Typography>{data[path] ?? data.business[path] ?? "--"}</Typography>
                </Grid>
                <CopyToClipboard
                  text={path === "email" ? data.email : data.phone}
                >
                  <CopyIcon
                    fontSize="small"
                    onClick={handleClick}
                    sx={{
                      display: path === "address" ? "none" : "",
                      color: "GrayText",
                      cursor: "pointer",
                    }}
                  />
                </CopyToClipboard>
              </Grid>
            ))}
            <Box
              display="flex"
              padding="8px 0"

            >
              <PlaceIcon />
              <Grid container p={'0 8px'}>
                {address_prop_path.map((path, index) => (
                  <Grid
                    item
                    xs={path === 'state' ? 6 : 5.5}
                    key={index}
                  >
                    <Typography>
                      {data?.address?.[path] || data?.business?.address?.[path]}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Snackbar
                open={open}
                autoHideDuration={3000}  // Duración de la alerta (en milisegundos)
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  ¡copiado!
                </Alert>
              </Snackbar>
            </Box>


            <Divider sx={{ m: "16px 0" }} />

            {credit_paths.map(({ label, path }) => (
              <Grid display="flex" p="5px 0" key={path}>
                <Typography mr={1}>{label}</Typography>
                <Typography>
                  <strong>{data[path] || "0"}</strong>
                </Typography>
              </Grid>
            ))}

            {props.type !== "purches" && props.type !== 'sales' && (
              <>
                <Divider sx={{ m: "16px 0" }} />

                <Typography p="8px 0">
                  <strong>CONTACTOS</strong>
                </Typography>
                <Box
                  display="flex"
                  flexDirection={'column'}
                  sx={{
                    maxHeight: '150px',
                    overflowY: 'scroll',
                    scrollbarWidth: "thin"
                  }}>
                  {data.contacts?.map((data, index) => (
                    <Grid display={'flex'} key={index} p={'8px 0'} >
                      <Avatar sx={{ mr: '16px' }}>{data.name.charAt(0).toUpperCase()}</Avatar>
                      <div style={{ flex: 1, minWidth: 0 }}>
                        <Stack direction={'row'} flexWrap={'wrap'} alignItems={'center'} >
                          <p style={{
                            fontSize: '18px',
                            marginRight: '16px',
                            display: 'block',
                            wordBreak: 'break-word',
                            overflowWrap: 'break-word',
                            whiteSpace: 'normal',
                            maxWidth: '100%',
                          }}>
                            {data.name}
                          </p>
                          <p
                            style={{
                              display: 'block',
                              flexWrap: 'wrap',
                              wordBreak: 'break-word',
                              overflowWrap: 'break-word',
                              fontSize: '17px',

                            }}
                          >{data.phone ?? " "}</p>
                        </Stack>
                        <Tooltip title={data.email}>
                          <p
                            style={{
                              width: data.email.length > 33 ? "250px" : "auto",
                              display: 'block',
                              wordBreak: 'break-word',
                              overflowWrap: 'break-word',
                              whiteSpace: data.email.length > 33 ? 'nowrap' : 'normal',
                              margin: 0,
                              maxWidth: '100%',
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >{data.email}
                          </p>
                        </Tooltip>
                      </div>
                    </Grid>
                  ))}
                  {!data.contacts && 'No hay contactos registrados'}
                </Box>
                <Divider sx={{ m: "16px 0" }} />
                <Typography p="8px 0">
                  <strong>COMENTARIOS</strong>
                </Typography>
                <Box sx={{ maxHeight: '150px', overflowY: 'auto' }}>
                  <List>
                    {data.comments && data.comments.map((comment, index) => (
                      <ListItem sx={{ p: '8px 0' }} key={index}>
                        <ListItemAvatar>
                          <Avatar sizes={30}>
                            <CommentIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                          primary={
                            <Typography
                              sx={{
                                display: 'block',
                                wordBreak: 'break-word',
                                overflowWrap: 'break-word',
                                whiteSpace: 'normal',
                                margin: 0,
                                maxWidth: '100%',
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {comment.comments}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                  {!data.comments && 'No hay comentarios en el registro'}
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </GenericDrawer >
  );
}
