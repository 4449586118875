import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Container from "../../layout/gridContainer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Menu from '@mui/material/Menu';
import { Grid, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InventoryTab from "./inventory/inventoryTab";
import Adjustment from "./operations/adjustment/adjustment"
import Scrap from "./operations/scrap/scrap"
import Transfers from "./operations/transfers/transfers"
import { useSearchParams } from "react-router-dom";



const InventoryInventory = () => {
	const [value, setValue] = useState(0);
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const [params, setParams] = useSearchParams();

	useEffect(() => {
		if (!params.has("tab")) {
			params.set("tab", "inventory");
			setParams(params);
			setValue(0);
		} else {
			if (params.get("tab") == "inventory") {
				setValue(0);
			} else {
				setValue(1)
			}
		}

	}, [params])


	return (
		<Container>
			<Grid container spacing={2}>

				<Grid item xs={12}>
					{params &&
						params.get("tab") === "inventory" ? (
						<InventoryTab />) :
						params.get("tab") === "adjustment" ? (
							<Adjustment></Adjustment>
						) : (params.get("tab") === "scrap" ?
							<Scrap></Scrap>
							: (params.get("tab") === "transfers") ?
								<Transfers></Transfers>
								: <h2> Selecciona algo </h2>
						)
					}
				</Grid>
			</Grid>
		</Container>
	);
}
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ pt: 3, pb: 3 }}>
					<div>{children}</div>
				</Box>
			)}
		</div>
	);
}




export default InventoryInventory;
