import { BASE_PATH } from "../utils/constants";
import { BaseService } from 'base-service-fran-dev';

export class InventoryService extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/ordered';
    super(baseUrl, endPoint)
  }
}


export class InventoryServiceSalesOrder extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/reserved';
    super(baseUrl, endPoint)
  }
}

export class InventoryServiceInventory extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/inventory/product';
    super(baseUrl, endPoint)
  }
}

export class InventoryServicePresentations extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/inventory/model';
    super(baseUrl, endPoint)
  }
}
