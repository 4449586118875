import React, { useState } from "react";
import { IconButton, CardHeader } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { Box } from "@mui/material";
import TableInfo from "./tableInfo";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import CurrencyFormat from "react-currency-format";
import { DrawerSupplier } from "../../../components/inventario/DrawerSupplier";
import OptionButton from "../../../components/inventario/optionButton";

const ClientTable = (props) => {
  const { data, UpdateInfo } = props;
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState({});
  const navigate = useNavigate();
  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell
          sx={{ minWidth: "20px", maxWidth: "100px" }}
          align="left"
          onClick={() => setOpenDrawer({ right2: true })}
        >
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                variant="subtitle2"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data.business_name}
              </Typography>
            }
            subheader={data.rfc}
          />
        </TableCell>
        <TableCell
          sx={{ minWidth: "20px", maxWidth: "100px" }}
          align="left"
          onClick={() => setOpenDrawer({ right2: true })}
        >
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                variant="subtitle2"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <CurrencyFormat
                  value={data.phone}
                  displayType={"text"}
                  format="(##) #### ####"
                />
              </Typography>
            }
            subheader={data.email}
          />
        </TableCell>
        <TableCell sx={{ width: '200px' }} >
          <OptionButton
            menu={19}
            id={data.id}
            openDrawer={() => setOpenDrawer({ right2: true })}
            UpdateInfo={UpdateInfo}
            type={"customer"}
          />
        </TableCell>
      </TableRow>

      <DrawerSupplier
        open={openDrawer}
        toggleDrawer={() => { }}
        id={data.id}
        close={() => setOpenDrawer({ right2: false })}
        type={"customer"}
      />
    </>
  );
};

export default ClientTable;
