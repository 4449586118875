import React, { useState } from "react";
import { Divider, Paper, Typography } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import "../../../scss/adminBusiness.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValBusinessProfile,
  valSchBusinessProfile,
} from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import BusinessManages from "../../../api/businessManages";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";

const Profile = ({ data, UpdateInfo }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const [modified, setModified] = useState(false);

  const UpdateProfile = async (id, formData) => {
    console.log(id);
    let updateProfile = new BusinessManages();
    const response = await updateProfile.editDataAuth(id, formData, logout);
    sendNotification(UseerrorOrsuccess(response));
    UpdateInfo();
  };

  const formik = useFormik({
    initialValues: initValBusinessProfile(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBusinessProfile()),
    onSubmit: async (formData) => {
      console.log(formData);

      if (formik.isValid) {
        // Solo si el formulario es válido se ejecuta la actualización
        setLoading(true);
        try {
          UpdateProfile(data.id, formData);
          setLoading(false);
          setModified(false);
        } catch (error) {
          // Maneja el error "correo invalido"
          setLoading(false);
          setModified(false);
        }
      }
    },
  });

  //Function like event to manage the event onKeyPress and permise only letters and spaces in blanck
  const handleNumbersOnly = (event) => {
    const regex = /^\d+$/; // Expresión regular to only permisse numbers
    if (!regex.test(event.key)) {
      event.preventDefault(); // Prevents the entry of characters other than letters
    }
  };

  return (
    <>
      <Paper sx={{ p: 0 }} className="paper" elevation={3}>
        <Grid
          sx={{
            bgcolor: "#f7f7f7",
            p: "14px 16px",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography fontSize={"20px"}>
            <strong>General</strong>
          </Typography>
        </Grid>
        <Divider />
        <form
          style={{ padding: "16px" }}
          onSubmit={formik.handleSubmit}
          onChange={() => {
            setModified(true);
          }}
        >
          <TextField
            fullWidth
            name="name"
            label="Nombre de la empresa"
            margin="dense"
            size="small"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name ? formik.errors.name : ""}
          />
          <TextField
            fullWidth
            name="turn_business"
            label="Giro de la empresa"
            margin="dense"
            size="small"
            value={formik.values.turn_business}
            onChange={formik.handleChange}
            error={
              formik.touched.turn_business &&
              Boolean(formik.errors.turn_business)
            }
            helperText={
              formik.touched.turn_business ? formik.errors.turn_business : ""
            }
          />
          <TextField
            fullWidth
            name="phone"
            label="Teléfono"
            margin="dense"
            size="small"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onKeyPress={handleNumbersOnly}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone ? formik.errors.phone : ""}
          />
          <TextField
            fullWidth
            name="email"
            label="Correo electrónico"
            margin="dense"
            size="small"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email ? formik.errors.email : ""}
          />

          <Grid display={"flex"} justifyContent={"flex-end"}>
            <LoadingButton
              disabled={!modified} // El botón se deshabilita solo si está en estado de carga
              className="button"
              type="submit"
              loading={loading}
              variant="contained"
              sx={{ width: "140px", mt: "16px", borderRadius: "60px" }}
            >
              Actualizar
            </LoadingButton>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default Profile;
