import { MenuItem, TextField } from '@mui/material';
import { ProductModel } from "../../../../api/productModel";
import React, { useEffect, useState } from 'react';

export default function MainModel({article,SetProductInfo,logout }) { 
   const [model, setModel] = useState("");
  return (
    <TextField
      label="Selecciona un modelo"
      variant="outlined"
      value={article?.main_model?.id || model}
      select
      size="small"
      fullWidth
    >
      {article?.model?.length > 0 ? (
        article.model.map((data) => (
          <MenuItem
            key={data.id}
            value={data.id}
            onChange={(e) => {
                const selectedId = e.target.value;
                setModel(selectedId)
              }}
            onClick={()=>{SetProductInfo({main_model:data.id})}}
          >
            {data.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>Cargando modelos...</MenuItem>
      )}
    </TextField>
  );
}
