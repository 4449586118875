import React, { useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import Container from "../../../../layout/gridContainer";
import { Button } from "@mui/material";
import ScrapService from "../../../../api/inventory/scrapService";
import AddScrap from "./addScrap";
import ScrapTable from "./scrapTable";
import { GenericEmptyTable, GenericSkeletonTable } from "../../../../components/GenericEmptyTable";
import BasicTable from "../../../../components/GenericTable";
import { useTableFilters } from "../../../../hooks/searchParams";
import PageParam from "../../../../components/PageParam";
import { GenericSearchNew } from "../../../../components/GenericSearch/GenericSearch";

const Transfers = () => {
  const [openModal, setOpenModal] = useState();
  const [totalpages, setTotalPages] = useState();
  const [openDrawer, setOpenDrawer] = useState({});
  const [table, setTable] = useState();
  const [loading, setLoading] = useState(false);
  const [params, setParams, handleSearch, handleInputChange, UdateTable, handleChange] =
    useTableFilters(ScrapService, setTable, setLoading, "", "tab");

  return (
    <Grid>
      <Grid
        container
        display={'flex'}
        justifyContent={'space-between'}
        alignItems="center"
        m={'24px 0'}>
        <Grid >
          <h2>Desechos</h2>
        </Grid>

        <Grid item xs={2}>
          <Button
            fullWidth
            onClick={() => setOpenDrawer({ right: true })}
            variant="contained"
            sx={{ borderRadius: 60, }}
          >
            Crear
          </Button>
          <AddScrap
            open={openDrawer}
            toggleDrawer={() => { }}
            close={() => setOpenDrawer({ right: false })}
            UpdateScrap={UdateTable}
          />
        </Grid>
      </Grid>
      <GenericSearchNew
        params={params}
        setParams={setParams}
        handleChanges={handleInputChange}
        handleSearch={handleSearch}
        name="pattern"
        label={"Buscar por nombre"} />

      <Grid item xs={12} className="margin-scrap-table">

        <>
          <BasicTable
            className="marginTable"
            titles={[
              "Justificación",
              "Fecha",
              "Almacén",
              "Estado",
              "Productos",
            ]}
          >

            {table?.data?.length > 0 &&
              table.data.map((data, index) => (
                <ScrapTable key={index} data={data} />
              ))
            }
            {loading && <GenericSkeletonTable />}
            {!table && !loading && (
              <GenericEmptyTable />
            )}
          </BasicTable>
          <div className="margin">
            <PageParam
              totalpages={table?.pagination || 1}
              params={params}
              handleChange={handleChange}
            />
          </div>
        </>



      </Grid>
    </Grid>
  );
};

export default Transfers;
