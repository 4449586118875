import React, { useEffect, useState } from 'react'
import { GenericDrawer } from '../../../../components/keskiCreator/genericDrawer'
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Avatar, Box, Chip, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Label } from '@mui/icons-material';
import Products from '../../../../api/productsManages';
import { useAuth } from "user-context-fran-dev";
import { useNavigate } from "react-router-dom";

export default function DetailsDrawer({ openDrawer, toggleDrawer, close, product, updateList }) {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const label = ["Tienda en linea", "punto de venta", "Catalogo"]
    const path = ["display_on_store", "display_on_pos", "show_in_gallery"]
    const [data, setData] = useState();

    useEffect(() => {
        getArticleInfo()
    }, [openDrawer])

    const getArticleInfo = () => {
        (async () => {
            let getArticle = new Products();
            let response = await getArticle.getAllByIdAuth(product?.id, logout);
            setData(response.data);
        })();
    };
    const fetchDataDelete = () => {
        (async () => {
            const service = new Products
            let response = await service.editDataAuth(product?.id, { is_archived: true }, logout);
            close();
            window.location.reload()
        })();
    };

    return (
        <GenericDrawer
            open={openDrawer}
            toggleDrawer={toggleDrawer}
            anchor={"right"}
            drawerWidth="30%"
        >
            {data &&

                <Box p={'16px'}>
                    <Grid
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography fontSize={"20px"} p={"8px 0"}>
                            <b>Vista previa</b>
                        </Typography>

                        <IconButton onClick={close}>
                            <CloseSharpIcon fontSize="small" />
                        </IconButton>
                    </Grid>
                    <Divider />
                    <Stack
                        direction={'row'}
                        spacing={3}
                        display={'flex'}
                        justifyContent={'flex-end'}
                        p={'8px 0'}
                    >
                        {['Eliminar', "Editar"].map((label) => (
                            <a
                                key={label}
                                style={{ color: "#60269E", cursor: "pointer" }}
                                onClick={() => {
                                    if (label === "Editar") {
                                        window.localStorage.setItem("menu", 10);
                                        navigate(`detail/${product?.id}`);
                                    } else {
                                        fetchDataDelete();
                                    }
                                }}
                            >
                                {label}
                            </a>
                        ))}

                    </Stack>
                    <Divider />
                    <div style={{ padding: '16px 0' }}>
                        <Grid
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <h2>{data?.name}</h2>
                            <Chip label={data.category.name} variant="outlined" />
                        </Grid>
                        <Grid
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            {['sku', 'phone'].map((path) => (
                                <Typography>
                                    {data[path]}
                                </Typography>
                            ))}
                        </Grid>
                        <Typography p={'16px 0'}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data?.description?.replace(/<\/?[^>]+(>|$)/g, "") || "Sin descripción"
                                }}
                            />
                        </Typography>
                        <Divider />
                        <Stack p={'16px 0'} spacing={1}>
                            <Typography>
                                <b>Tiempo de preparacion: </b>{data.preparation_time.toString()}
                            </Typography>

                            <Typography display={'flex'}>
                                <b style={{ paddingRight: '5px' }}>Visible en:  </b>
                                <p style={{ display: data.display_on_store ? '' : 'none' }}> Tienda en linea,</p>
                                <p style={{ display: data.display_on_pos ? '' : 'none' }}>punto de venta </p>
                                <p style={{ display: data.show_in_gallery ? '' : 'none' }}> Catalogo </p>
                            </Typography>
                            <Typography>
                                <b>Descuento: </b>{data.model[0].presentation[0].discount || 0}%
                            </Typography>

                        </Stack>
                        <Divider />
                        {data?.model?.[0]?.presentation?.[0]?.name && (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography p={'16px 0'}>
                                    MODELOS Y PRESENTACIONES
                                </Typography>
                                <Grid display={'flex'}>
                                    <Avatar
                                        alt="Remy Sharp"
                                        src={data?.main_model?.image?.url}
                                        sx={{ width: 56, height: 56, mr: 2 }} />

                                    <Stack>
                                        <Typography>
                                            {data.model[0].name === "--"
                                                ? "modelo principal "
                                                : data.model[0].name}
                                        </Typography>
                                        <Typography
                                            color={
                                                data.model[0].presentation[0].is_active
                                                    ? 'success'
                                                    : 'warning'}
                                        >
                                            {data.model[0].presentation[0].is_active ? 'Activo' : ' Desactivado'}
                                        </Typography>
                                    </Stack>

                                </Grid>
                                <ol style={{ paddingLeft: '16px', paddingTop: '8px' }}>
                                    {data.model[0].presentation.map((val, index) => (
                                        <li key={index}>
                                            <Grid
                                                display={'flex'}
                                                justifyContent={'space-between'}
                                                m={'8px 0'}
                                            >
                                                <Stack direction={'column'}>
                                                    <p >
                                                        {val.name === "--" ? data.name : val.name}
                                                    </p>
                                                    <p>{val.barcode}</p>
                                                </Stack>

                                                <Stack direction={'column'}>
                                                    <p>costo: ${val.cost}</p>
                                                    <p>precio: ${val.price}</p>
                                                </Stack>
                                            </Grid>
                                        </li>
                                    ))}
                                </ol>
                            </div>
                        )}
                    </div>
                </Box>
            }
        </GenericDrawer>
    )
}
