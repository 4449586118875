import { BASE_PATH } from "../utils/constants";
import {BaseService} from 'base-service-fran-dev';

export class AvailablePresentation extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'inventory/available/presentation';
      super(baseUrl, endPoint)
    } 
}

export class AvailableAdjustment extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/available';
    super(baseUrl, endPoint)
  }
}   


export class AvailableAdjustmentAdd extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/adjustment';
    super(baseUrl, endPoint)
  }
}  

export class AvailablePresentationInventory extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/available/presentation/inventory';
    super(baseUrl, endPoint)
  } 
}


