import React, { useState } from "react";
import { Divider, Paper, Typography } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import "../../../scss/adminBusiness.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValBusinessTax,
  valSchBusinessTax,
} from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import BusinessManages from "../../../api/businessManages";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";

const TaxInformation = ({ data, UpdateInfo }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const [modified, setModified] = useState(false);

  const UpdateTaxt = async (id, formData) => {
    let updateTaxt = new BusinessManages();
    const response = await updateTaxt.editDataAuth(id, formData, logout);
    sendNotification(UseerrorOrsuccess(response));
    UpdateInfo();
  };

  const formik = useFormik({
    initialValues: initValBusinessTax(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBusinessTax()),
    onSubmit: async (formData) => {
      console.log(formData);

      setLoading(true);
      UpdateTaxt(data.id, formData);
      setLoading(false);
      setModified(false);
    },
  });

  return (
    <Paper sx={{ m: "24px 0", p: 0 }} className="paper" elevation={3}>
      <Grid
        sx={{
          bgcolor: "#f7f7f7",
          p: "16px",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Typography fontSize={"20px"}>
          <strong>Información fiscal</strong>
        </Typography>
      </Grid>
      <Divider />
      <form
        style={{ padding: "16px" }}
        onSubmit={formik.handleSubmit}
        onChange={() => setModified(true)}
      >
        <Grid>
          <TextField
            fullWidth
            name="business_name"
            label="Razón social"
            margin="dense"
            size="small"
            value={formik.values.business_name}
            onChange={formik.handleChange}
            error={
              formik.touched.business_name &&
              Boolean(formik.errors.business_name)
            }
            helperText={
              formik.touched.business_name ? formik.errors.business_name : ""
            }
          />
          <TextField
            fullWidth
            name="rfc"
            label="RFC"
            margin="dense"
            size="small"
            value={formik.values.rfc}
            onChange={formik.handleChange}
            error={formik.touched.rfc && Boolean(formik.errors.rfc)}
            helperText={formik.touched.rfc ? formik.errors.rfc : ""}
          />

          <Grid display={"flex"} justifyContent={"flex-end"}>
            <LoadingButton
              fullWidth
              className="button"
              type="submit"
              loading={loading}
              disabled={!modified}
              variant="contained"
              sx={{ width: "140px", mt: "16px", borderRadius: "60px" }}
            >
              Actualizar
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default TaxInformation;
