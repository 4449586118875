import React, { useState, useEffect } from 'react'
import { useFormik } from "formik";
import { useAuth } from "user-context-fran-dev";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField, Grid, Button, Typography } from "@mui/material";
import { initValPhonebook, valPhonebook } from "../../utils/formHelper"
import BasicModal from "../../components/GenericModal";
import ContactService from "../../api/contactService";
import { UseerrorOrsuccess, useNotification } from '../../hooks/snackBarResponse'
import { useParams } from 'react-router-dom';
import { GenericDrawer } from '../keskiCreator/genericDrawer';
import CloseIcon from '@mui/icons-material/Close';


const AddContact = ({ setOpen, open, UpdateInfo, target }) => {

  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const { id } = useParams()
  const supplierService = new ContactService()
  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });

  const toggleDrawer = (anchor, open) => {
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open,
    }));
  };

  const formik = useFormik({
    initialValues: initValPhonebook(id),
    enableReinitialize: true,
    validationSchema: false,
    onSubmit: async (formData, { resetForm }) => {
      if (target === 'supplier') {
        formik.values.supplier = id
      } else {
        formik.values.client = id
      }
      setLoading(true);
      let response = await supplierService.postDataAuth(formData, logout);
      if (Number(response.status) < 300) {
        sendNotification(UseerrorOrsuccess(response))
        UpdateInfo()
        resetForm()
        setLoading(false);
        toggleDrawer("right", false)

      }
      else {
        sendNotification(UseerrorOrsuccess(response))
        setLoading(false);
      }
    },
  });
  useEffect(() => {
    return () => {
      formik.resetForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Grid display={'flex'} justifyContent={'flex-start'}>
        <Button
          variant='text'
          sx={{ color: "#60269E", fontWeight: "600", marginTop: '8px', p: 0 }}
          onClick={() => { toggleDrawer("right", true) }} underline="none" className="link">
          + Crear Contacto
        </Button>
      </Grid>
      <GenericDrawer
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        anchor={"right"}
        drawerWidth="30%"
      >
        <Grid
          p={"16px 24px"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Typography >
            <strong>Agregar contacto</strong>
          </Typography>
          <CloseIcon
            sx={{ cursor: "pointer", }}
            onClick={() => toggleDrawer("right", false)}
          />
        </Grid>
        <form style={{ padding: '16px' }} onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="name"
                label="Nombre"
                margin="dense"
                size="small"
                value={formik.values.business_name}
                onChange={formik.handleChange}
                error={formik.touched.business_name && Boolean(formik.errors.business_name)}

                helperText={formik.touched.business_name ? formik.errors.business_name : ""} />
            </Grid>
            <Grid item xs={12}>

            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="email"
                label="Email"
                margin="dense"
                size="small"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}

                helperText={formik.touched.email ? formik.errors.email : ""} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="phone"
                label="Telefono"
                margin="dense"
                size="small"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}

                helperText={formik.touched.phone ? formik.errors.phone : ""} />
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid
                item
                xs={12}
                sm={7}
                md={6}
                lg={6}
                xl={6}
                sx={{ marginTop: "16px" }}
              >
                <LoadingButton
                  loading={loading}
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ color: "#fff", borderRadius: 60 }}
                >
                  Agregar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>

        </form>
      </GenericDrawer></>
  )
}

export default AddContact