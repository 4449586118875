import { BASE_PATH, BASE_PATH_DASH } from "../../utils/constants";
import { BaseService } from "base-service-fran-dev";

export class Filters extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "catalog/feature";
    super(baseUrl, endPoint);
  }
}

export class ProductsFilters extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "catalog/product_feature";
    super(baseUrl, endPoint);
  }
}

export class FilterLabel extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "catalog/label";
    super(baseUrl, endPoint);
  }
}
