import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormGroup,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { TextField, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import "../../scss/adminBusiness.scss";
import Link from "@mui/material/Link";
import Container from "../../layout/gridContainer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import BasicModal from "../../components/GenericModal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { formatISO9075 } from "date-fns";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValBranchName, valSchBranchName } from "../../utils/formHelper";
import BranchManages from "../../api/branchManages";
import { useAuth } from "user-context-fran-dev";
/* import { initValBranchContact, valSchBranchContact } from "../../utils/formHelper"; */
import { initValSchedule, valSchSchedule } from "../../utils/formHelper";
import ScheduleManages from "../../api/scheduleManages";
import { useParams } from "react-router-dom";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../hooks/snackBarResponse";
//import GenericAddress from "../../components/GenericAddress";
import WarehouseService from "../../api/warehouseService";
import {
  initValBranchWarehouse,
  valSchBranchWarehouse,
} from "../../utils/formHelper";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { GenericDrawer } from "../../components/keskiCreator/genericDrawer";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { GenericAddressDrawer } from "../../components/GenericAddAddress";

const BranchDetails = ({ setReload }) => {
  const { logout } = useAuth();
  const { id } = useParams();
  const [dataBranch, setDataBranch] = useState("");
  const [modified, setModified] = useState(false);
  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });

  const toggleDrawer = (anchor, open) => {
    // console.log("Toggling drawer", anchor, open);
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
  };

  const UpdateBranchInfo = () => {
    (async () => {
      let getBranch = new BranchManages();
      let response = await getBranch.getAllById(id, logout);
      console.log("Respuest de admin branch", response);
      setDataBranch(response.data);
    })();
  };

  useEffect(() => {
    UpdateBranchInfo();

    return () => {
      setReload((prev) => !prev);
      window.localStorage.setItem("menu", 1);
    };
  }, []);

  return (
    <Container>
      <Grid sx={{ paddingBottom: "2%" }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          className="title-card"
          m={"24px 0"}
        >
          <strong>Sucursales</strong>
        </Typography>
      </Grid>

      <Grid
        container
        spacing={4}
        display={"flex"}
        flexDirection={{ xs: "column", sm: "column", md: "row" }}
      >
        <Grid item xs={12} sm={12} md={8}>
          <BranchName data={dataBranch} UpdateInfo={UpdateBranchInfo} />
          <WareHouse data={dataBranch} UpdateInfo={UpdateBranchInfo} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          display={"flex"}
          flexDirection={"column"}
        >
          {" "}
          <Paper elevation={3} sx={{ mb: "24px" }}>
            <Grid
              p={"16px 24px"}
              display={"flex"}
              justifyContent={"space-between"}
              bgcolor={"#f7f7f7"}
            >
              <Typography>
                <strong>Dirección</strong>
              </Typography>
              <EditOutlinedIcon
                sx={{ cursor: "pointer" }}
                onClick={() => toggleDrawer("right", true)}
              />
            </Grid>
            <Divider />
            <Grid p={"16px 24px"}>
              {dataBranch.address ? (
                <>
                  <Typography fontSize={"18px"}>
                    {dataBranch?.address?.street}
                  </Typography>
                  <Typography fontSize={"18px"}>
                    {dataBranch?.address?.suburb} C.P{" "}
                    {dataBranch?.address?.postal_code}
                  </Typography>
                  <Typography fontSize={"18px"}>
                    {dataBranch?.address?.city}, {dataBranch?.address?.state}
                  </Typography>
                </>
              ) : (
                <Typography align="center">
                  No hay datos para mostrar
                </Typography>
              )}
            </Grid>
            <GenericAddressDrawer
              openDrawer={openDrawer}
              toggleDrawer={toggleDrawer}
              data={dataBranch}
              UpdateInfo={UpdateBranchInfo}
              target={"branch"}
              close={() => toggleDrawer("right", false)}
            />
          </Paper>
          <Paper elevation={3} sx={{ mb: "10px" }}>
            <Grid
              p={"16px 24px"}
              display={"flex"}
              justifyContent={"space-between"}
              bgcolor={"#f7f7f7"}
            >
              <Typography>
                <strong>Horarios</strong>
              </Typography>
            </Grid>
            <Divider />
            <Schedule data={dataBranch} UpdateInfo={UpdateBranchInfo} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

const Schedule = ({ data, UpdateInfo }) => {
  const weekDays = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ];
  const { logout } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [dayInfo, setDayInfo] = useState("");
  const [titleDay, setTitleDay] = useState("");
  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });
  const [msg, sendNotification] = useNotification();


  const toggleDrawer = (anchor, open) => {
    // console.log("Toggling drawer", anchor, open);
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
  };
  const DeleteDay = (id) => {
    (async () => {
      let setSchedule = new ScheduleManages();
      let response = await setSchedule.deleteAuth(id, logout);
      UpdateInfo();
      sendNotification(UseerrorOrsuccess(response));
    })();
  };

  return (
    <Box padding={"8px"}>
      <TimeModal
        openTime={openDrawer}
        toggleDrawer={toggleDrawer}
        dayInfo={dayInfo}
        data={data}
        titleDay={titleDay}
        UpdateInfo={UpdateInfo}
        close={() => toggleDrawer("right1", false)}
      />
      <List>
        {data.schedule &&
          data.schedule.map((data, index) => (
            <div key={index}>
              <ListItem>
                <ListItemText
                  sx={{ mr: "24px" }}
                  primary={weekDays[data.day]}
                />
                <ListItemText
                  primary={`${data.open_time} - ${data.close_time}`}
                />
                <Link
                  edge="end"
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => {
                    DeleteDay(data.id);
                  }}
                >
                  Eliminar
                </Link>
              </ListItem>
            </div>
          ))}
      </List>
      <Button sx={{ p: "8 16px" }} onClick={() => toggleDrawer("right1", true)}>
        + Agregar horario
      </Button>
    </Box>
  );
};

const TimeModal = ({
  openTime,
  toggleDrawer,
  dayInfo,
  data,
  UpdateInfo,
  close,
}) => {
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const [msg, sendNotification] = useNotification();


  const weekDays = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ];

  const handleChange = (event) => {
    setSelectedDay(event.target.value);
  };

  const setSchedule = (formData) => {
    setLoading(true);
    const data = {
      day: selectedDay,
      open_time: formatISO9075(formData.open_time, { representation: "time" }),
      close_time: formatISO9075(formData.close_time, { representation: "time", }),
      branch: formData.branch,
    };
    console.log(data);
    if (dayInfo) {
      (async () => {
        let setSchedule = new ScheduleManages();
        let response = await setSchedule.editDataAuth(dayInfo.id, data, logout);
        UpdateInfo();
        sendNotification(UseerrorOrsuccess(response));
        toggleDrawer("right1", false);
      })();
    } else {
      (async () => {
        let setSchedule = new ScheduleManages();
        let response = await setSchedule.postDataAuth(data, logout);
        UpdateInfo();
        sendNotification(UseerrorOrsuccess(response));
        toggleDrawer("right1", false);
      })();
    }

    setLoading(false);
  };

  let formik = useFormik({
    initialValues: initValSchedule(dayInfo, data.id),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchSchedule()),
    onSubmit: async (formData) => {
      setSchedule(formData);
      formik.resetForm();
    },
  });

  return (
    <>
      <GenericDrawer
        open={openTime}
        toggleDrawer={toggleDrawer}
        anchor={"right1"}
        drawerWidth="30%"
      >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: "16px",
          }}
        >
          <Typography fontSize={"20px"}>
            <b>{!dayInfo ? "Agregar Horario" : "Editar Horario"}</b>
          </Typography>

          <IconButton onClick={close}>
            <CloseSharpIcon fontSize="small" />
          </IconButton>
        </Grid>
        <form style={{ padding: "0 16px" }} onSubmit={formik.handleSubmit}>
          <TextField
            select
            fullWidth
            margin="normal"
            label={"Seleccionar día"}
            value={selectedDay}
            onChange={handleChange}
          >
            {weekDays.map((day, index) => (
              <MenuItem key={index} value={index}>
                {day}
              </MenuItem>
            ))}
          </TextField>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid
              display={"flex"}
              justifyContent={"space-between"}
              m={"16px 0"}
            >
              <TimePicker
                views={['hours', 'minutes']}
                label="Abre a las"
                id="open_time"
                name="open_time"
                closeOnSelect={false}
                //value={formik.values.open_time}
                onChange={(newValue) => {
                  formik.setFieldValue("open_time", Date.parse(newValue));
                }}
                sx={{ mr: "8px" }}
                renderInput={(params) => (
                  <TextField
                    helperText={
                      formik.touched.open_time ? formik.errors.open_time : ""
                    }
                    {...params}
                  />
                )}
              />

              <TimePicker
                label="Cierra a las"
                id="close_time"
                name="close_time"
                //value={formik.values.close_time}
                closeOnSelect={false}
                onChange={(newValue) => {
                  formik.setFieldValue("close_time", Date.parse(newValue));
                }}
                renderInput={(params) => (
                  <TextField
                    helperText={
                      formik.touched.close_time ? formik.errors.close_time : ""
                    }
                    {...params}
                  />
                )}
              />
            </Grid>
          </LocalizationProvider>
          <Grid display={"flex"} justifyContent={"flex-end"}>
            <LoadingButton
              loading={loading}
              disabled={!formik.values.close_time || !formik.values.open_time}
              type="submit"
              variant="contained"
              sx={{ width: "30%", borderRadius: 60 }}
            >
              {!dayInfo ? "Agregar" : "Editar"}
            </LoadingButton>
          </Grid>
        </form>
      </GenericDrawer>
    </>
  );
};

const BranchName = ({ data, UpdateInfo }) => {
  const [loading, setLoading] = useState(false);
  const [msg, sendNotification] = useNotification();
  const [modified, setModified] = useState(false);
  const { logout } = useAuth();

  console.log("response", data);

  const formik = useFormik({
    initialValues: initValBranchName(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBranchName()),
    onSubmit: async (formData) => {
      setLoading(true);
      console.log(formData);
      let getBranch = new BranchManages();
      let response = await getBranch.editDataAuth(data.id, formData, logout);

      sendNotification(UseerrorOrsuccess(response));
      UpdateInfo();

      setLoading(false);
      setModified(false);
    },
  });

  return (
    <Paper elevation={3} sx={{ m: "0 0 24px" }}>
      <Grid
        sx={{
          bgcolor: "#f7f7f7",
          p: "14px 16px",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Typography fontSize={"20px"}>
          <strong>General</strong>
        </Typography>
      </Grid>
      <Divider />
      <Grid padding={"16px"}>
        <form onSubmit={formik.handleSubmit} onChange={() => setModified(true)}>
          <Grid>
            <TextField
              fullWidth
              name="name"
              label="Nombre del negocio"
              placeholder="Nombre del negocio"
              size="small"
              margin="normal"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              variant="outlined"
              helperText={formik.touched.name ? formik.errors.name : ""}
            />
            <TextField
              fullWidth
              name="phone"
              label="Teléfono"
              size="small"
              margin="normal"
              placeholder="Teléfono"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              variant="outlined"
              helperText={formik.touched.phone ? formik.errors.phone : ""}
            />

            <Grid display={"flex"} justifyContent={"flex-end"} pt={"16px"}>
              <LoadingButton
                sx={{ borderRadius: 50 }}
                type="submit"
                loading={loading}
                variant="contained"
                disabled={!modified}
              >
                Actualizar
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Paper>
  );
};

const WareHouse = ({ data, UpdateInfo }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const [modified, setModified] = useState(false);

  const formik = useFormik({
    initialValues: initValBranchWarehouse(data?.warehouse),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBranchWarehouse()),
    onSubmit: async (formData) => {
      setLoading(true);
      console.log(formData);
      let setWareHouse = new WarehouseService();
      let response = await setWareHouse.editDataAuth(
        data?.warehouse.id,
        formData,
        logout
      );
      UpdateInfo();
      sendNotification(UseerrorOrsuccess(response));
      setLoading(false);
      setModified(false);
    },
  });

  return (
    <Paper elevation={3}>
      <Grid
        sx={{
          bgcolor: "#f7f7f7",
          p: "14px 16px",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Typography fontSize={"20px"}>
          <strong>Almacén</strong>
        </Typography>
      </Grid>
      <Divider />
      <form
        style={{ padding: "16px" }}
        onSubmit={formik.handleSubmit}
        onChange={() => setModified(true)}
      >
        <TextField
          fullWidth
          name="name"
          label="Almacén"
          margin="dense"
          size="small"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          variant="outlined"
          helperText={formik.touched.name ? formik.errors.name : ""}
        />

        <FormGroup>
          <Box
            sx={{ display: "flex", alignItems: "center", padding: "16px 0" }}
          >
            <Typography variant="body1" component="div">
              ¿Stock disponible para venta en línea?
            </Typography>
            <Switch
              name="sell_products_online"
              checked={formik.values.sell_products_online}
              onChange={formik.handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
        </FormGroup>
        <Grid display={"flex"} justifyContent={"flex-end"} pt={"16px"}>
          <LoadingButton
            sx={{ borderRadius: 50 }}
            type="submit"
            loading={loading}
            variant="contained"
            disabled={!modified}
          >
            Actualizar
          </LoadingButton>
        </Grid>
      </form>
    </Paper>
  );
};

export default BranchDetails;
