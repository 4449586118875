import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Suppliers } from "../../../../api/supplierService";
import { useAuth } from "user-context-fran-dev";
import Container from "../../../../layout/gridContainer";
import GenericProfile, {
  ProfileForm,
} from "../../../../components/GenericProfile";
import { PaymentConditionsForm } from "../../../../components/paymentConditions";
import { Avatar, Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import { Card } from '../../../catalog/articleDetail/articleData'
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { GenericAddressDrawer } from "../../../../components/GenericAddAddress";
import Phonebook from "../../../../components/inventario/phonebook";
import Comments from "../../../../components/inventario/comments";
import GenericAddress from "../../../../components/GenericAddress";

const SuppliersDetails = () => {
  const { logout } = useAuth();
  const [loading, setloading] = useState();
  const { id } = useParams();
  const [dataSupplier, setDataSuppllier] = useState();
  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });

  const toggleDrawer = (anchor, open) => {
    // console.log("Toggling drawer", anchor, open);
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
  };

  useEffect(() => {
    GetInfoSupplier();
  }, []);

  const GetInfoSupplier = async () => {
    try {
      const getSupplier = new Suppliers();
      const response = await getSupplier.getAllByIdAuth(id, logout);
      console.log("response", response);
      setDataSuppllier(response.data || []);
    } catch (error) {
      console.error("Error fetching supplier info:", error);
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "#F5F5F5",
          padding: "8px 20px",
          border: "1px solid #D9D9D9",
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={11}>
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              className="title-card"
              sx={{ padding: "0", color: "#605D5D" }}
            >
              <strong>Inventario</strong>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <GridViewIcon sx={{ color: "#605D5D" }} />
          </Grid>
        </Grid>
      </Grid>
      {dataSupplier && (
        <Container>
          <Grid item xs={12} sx={{ marginBottom: "20px" }}>
            <Grid container spacing={0}>
              <Grid item xs={11}>
                <Typography
                  variant="h5"
                  gutterBottom
                  component="div"
                  className="title-card"
                  sx={{ paddingBottom: "0" }}
                >
                  <strong>Proveedores</strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  sx={{
                    borderRadius: "50%",
                    minWidth: "40px",
                    padding: "2px",
                    height: "40px",
                  }}
                  variant="outlined"
                >
                  <MoreHorizIcon sx={{ width: "30px", height: "30px" }} />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} spacing={3} >
            <Grid item xs={12} sm={12} md={8}>
              <Card title={'Perfil'} fontSize={'20px'} padding={'14px'}>
                <ProfileForm
                  data={dataSupplier}
                  UpdateInfo={GetInfoSupplier}
                  target={"supplier"}
                />
              </Card>
              <Card title={'Condiciones de pago'} fontSize={'20px'} padding={'14px'}>
                <PaymentConditionsForm
                  data={dataSupplier}
                  UpdateInfo={GetInfoSupplier}
                  target={"supplier"}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} >
              <Card title={'Estado'} display='flex' align='center'>
                <div style={{ alignItems: 'center', display: 'flex', }}>
                  <CalendarMonthIcon /> <p style={{ padding: '0 8px' }}>Creado</p>
                </div>
                <strong>
                  {dayjs(dataSupplier.date_created).format(
                    "MMM DD, YYYY"
                  )}
                </strong>
              </Card>
              <GenericAddress
                data={dataSupplier}
                UpdateInfo={GetInfoSupplier}
                target={"supplier"}
              />
              <Phonebook
                service={Suppliers}
                data={dataSupplier}
                UpdateInfo={GetInfoSupplier}
                target={"supplier"}
              />
              <Comments
                data={dataSupplier}
                UpdateInfo={GetInfoSupplier}
                target={"supplier"}
              />
            </Grid>
          </Stack>

        </Container >

      )}
    </>
  );
};
export default SuppliersDetails;
