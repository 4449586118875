import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import GenericSizeModal from "../../components/GenericSizeModal";
import { Suppliers } from "../../api/supplierService";
import { useAuth } from "user-context-fran-dev";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import PurchaseService from "../../api/purchaseService";
import SalesService from "../../api/salesService";
import CustomerService from "../../api/customerService";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../hooks/snackBarResponse";
import GenericPaymentConditions from "../GenericPaymentConditions";
import CurrencyFormat from "react-currency-format";
import { GenericEmptyTable } from "../../components/GenericEmptyTable";
import { ArticleExpandableView } from "./ArticleExpandableView";
import { GenericHeaderTitle } from "../GenericHeader";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { GenericDrawer } from "../keskiCreator/genericDrawer";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useFormik } from "formik";
import { DrawerSupplier } from "./DrawerSupplier";
import { SelectAllSharp } from "@mui/icons-material";

// index:
//  -sales comp
//  -purchess
//  -sub components Drawer

const Item = styled(Paper)(({ theme }) => ({
  margin: "16px 0",
  padding: "8px",
}));

const InventoryGenericDetailsView = (props) => {
  const { name, pay, data, children, type, reloadDataOforder, status } = props;
  const { logout } = useAuth();
  const [dataList, setData] = useState("");
  const [allData, SetAllData] = useState("");
  const [datalength, setDatalength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [msg, sendNotification] = useNotification();
  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });
  console.log("data------------->", data);

  const toggleDrawer = (anchor, open) => {
    console.log("Toggling drawer", anchor, open);
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
    type === "sales" ? clientList() : supplierList();
  };

  const initSearchParameters = () => {
    return {
      name: "", // Name field initialized as an empty string
    };
  };

  const formik = useFormik({
    initialValues: initSearchParameters(),
    validationSchema: false,
    onSubmit: async (formData) => {
      const requestFilter = [];
      requestFilter.push({ key: "pattern", value: formik.values.name });
      try {
        const services = {
          purchase: new Suppliers(),
          sales: new CustomerService(),
        };
        const service = services[type];
        let response = await service.filter(requestFilter, logout);
        console.log("suppliers", response);
        setData(response.data);
        setDatalength(response.data.length);
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    },
  });

  const clientList = () => {
    (async () => {
      let getClients = new CustomerService();
      let response = await getClients.getAllAuth(logout);
      console.log("clients", response);
      SetAllData(response.data);
    })();
  };

  const supplierList = () => {
    (async () => {
      let getUsers = new Suppliers();
      let response = await getUsers.getAllAuth(logout);
      console.log("suppliers", response);
      SetAllData(response.data);
    })();
  };

  const editSupplier = (id) => {
    (async () => {
      //setLoading(true)
      let getPurchases = new PurchaseService();
      let response = await getPurchases.editDataAuth(
        data.id, // oreder
        { supplier: id }, //supplier id
        logout
      );
      console.log(response);
      sendNotification(UseerrorOrsuccess(response));
      reloadDataOforder();
      setLoading(false);
      toggleDrawer("right1", false);
    })();
  };

  const editClient = (id) => {
    (async () => {
      setLoading(true);
      let getPurchases = new SalesService();
      let response = await getPurchases.editDataAuth(
        data.id,
        { client: id },
        logout
      );
      console.log(response);
      sendNotification(UseerrorOrsuccess(response));
      reloadDataOforder();
      setLoading(false);
      toggleDrawer("right1", false);
    })();
  };

  return (
    <>
      {data && (
        <>
          <Grid
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            container
            spacing={3}
          >
            <Grid item xs>
              <GenericHeaderTitle title={name}>
                {status === 1 || data.status === 1 ? (
                  <>
                    <IconButton
                      onClick={() => toggleDrawer("right2", true)}
                      sx={{ padding: 0, mr: 3 }}
                    >
                      <RemoveRedEyeOutlinedIcon
                        fontSize="small"
                        sx={{ color: "black" }}
                      />
                    </IconButton>

                    <EditOutlinedIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => toggleDrawer("right1", true)}
                    />
                  </>
                ) : (
                  <></>
                )}
              </GenericHeaderTitle>
              <Stack p={"16px 16px 0 16px "} spacing={1.5}>
                <Typography variant="h3" fontSize={"24px"}>
                  {data?.supplier?.business_name ||
                    data?.client?.business_name ||
                    "Nombre de la empresa"}
                </Typography>

                <Typography fontSize={"16px"}>
                  {data?.supplier?.rfc || data?.client?.rfc || ""}
                </Typography>

                <Grid item container direction="row">
                  <LocalPhoneOutlinedIcon sx={{ mr: 1 }} />
                  {data.supplier ? (
                    <CurrencyFormat
                      value={data?.supplier?.phone || data?.client?.phone}
                      displayType={"text"}
                      format="(##) #### ####"
                    />
                  ) : (
                    <Typography>Teléfono</Typography>
                  )}
                </Grid>
                <Grid item container direction="row">
                  <AlternateEmailIcon sx={{ mr: 1 }} />

                  <a>
                    {data?.supplier?.email ||
                      data?.client?.email ||
                      "correo electrónico"}
                  </a>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs>
              <GenericHeaderTitle title={pay}>
                {status === 1 || data.status === 1 ? (
                  <>
                    <EditOutlinedIcon
                      disabled={true}
                      sx={{ cursor: "pointer" }}
                      onClick={() => toggleDrawer("right", true)}
                    />
                  </>
                ) : (
                  <></>
                )}
              </GenericHeaderTitle>
              <Stack spacing={1} pt={"16px"}>
                <Grid item xs={12}>
                  <Grid item container direction="row">
                    <Grid item xs={4} className="phone">
                      <a>Días de crédito:</a>
                    </Grid>
                    <Grid item xs>
                      <Grid container justifyContent="flex-start">
                        {data.credit_days ? data.credit_days : "0"}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {type !== "purchase" && (
                  <Grid item xs={12}>
                    <Grid item container direction="row">
                      <Grid item xs={4} className="phone">
                        <a>Límite de crédito:</a>
                      </Grid>
                      <Grid item xs>
                        <Grid container justifyContent="flex-start">
                          <CurrencyFormat
                            value={
                              data.supplier?.credit_limit
                                ? data.supplier.credit_limit
                                : "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid item container direction="row">
                    <Grid item xs={4} className="phone">
                      <a>Descuento:</a>
                    </Grid>
                    <Grid item xs>
                      <Grid container justifyContent="flex-start">
                        {data?.discount ? data.discount + "%" : "0%"}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item container direction="row">
                    <Grid item xs={4} className="phone">
                      <a>IVA:</a>
                    </Grid>
                    <Grid item xs>
                      <Grid container justifyContent="flex-start">
                        {data.iva ? data.iva + "%" : "- -"}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>

          {/*--------- Add article product-------------------> */}
          <Grid container spacing={3}>
            <Grid item xs className="table">
              {data.status === 1 && (
                <Grid item xs={12} mb={"16px"}>
                  <ArticleExpandableView
                    type={type}
                    reloadDataOforder={reloadDataOforder}
                    wh={data}
                  />
                </Grid>
              )}
              <GenericHeaderTitle title="Productos" />
              <Grid item xs>
                {children}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {/*-------- sub components Drawer------------------> */}
      <GenericPaymentConditions
        data={data}
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        UpdateInfo={reloadDataOforder}
        target={type}
        close={() => toggleDrawer("right", false)}
      />
      <GenericDrawer
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        anchor={"right1"}
        drawerWidth="30%"
      >
        <Box p={"16px"}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: "8px",
            }}
          >
            <Typography fontSize={"20px"} p={"8px 0"}>
              <b>Seleccionar proveedor</b>
            </Typography>

            <IconButton onClick={() => toggleDrawer("right1", false)}>
              <CloseSharpIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            component={"form"}
            onSubmit={formik.handleSubmit}
          >
            <TextField
              label="Buscar por nombre"
              size="small"
              margin="normal"
              name="name"
              sx={{ width: "70%" }}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            <IconButton
              onClick={() => {
                formik.resetForm();
                setDatalength(0);
                setData("");
              }}
            >
              <DeleteOutlineOutlinedIcon
                sx={{
                  color: "GrayText",
                  fontSize: 32,
                }}
              />
            </IconButton>
            <LoadingButton
              loading={false}
              type="submit"
              variant="outlined"
              sx={{ borderRadius: "60px" }}
            >
              buscar
            </LoadingButton>
          </Grid>
          <Typography p={"8px 0"}>
            {dataList ? `Resultados: ` + datalength : "todo:"}
          </Typography>
          {allData ? (
            (!dataList ? allData : dataList || []).map((data, key) => (
              <Item key={key} value={data.id} elevation={4}>
                <Typography>
                  <b>{data.business_name}</b>
                </Typography>

                <Grid
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography color="grey">{data.rfc}</Typography>
                  <LoadingButton
                    variant="contained"
                    sx={{ borderRadius: "60px", textTransform: "none" }}
                    size="small"
                    onClick={() =>
                      type === "purchase"
                        ? editSupplier(data.id)
                        : editClient(data.id)
                    }
                  >
                    seleccionar
                  </LoadingButton>
                </Grid>
              </Item>
            ))
          ) : (
            <Item elevation={4}>
              <Grid
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography>
                  <b>No hay contenido disponible aún</b>
                </Typography>
                <Typography align="center" m={"8px 0"}>
                  Agrega ahora un proveedor para que se muestre aquí
                </Typography>
                <LoadingButton
                  variant="contained"
                  sx={{
                    borderRadius: "60px",
                    textTransform: "none",
                    m: "8px 0",
                  }}
                  size="small"
                >
                  agregar proveedor
                </LoadingButton>
                <Typography align="center" m={"8px 0"} fontSize={"15px"}>
                  ¿Necesitas ayuda? Checa nuestro{" "}
                  <Link>contenido para más inspiración</Link>
                </Typography>
              </Grid>
            </Item>
          )}
        </Box>
      </GenericDrawer>
      <DrawerSupplier
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        logout={logout}
        id={type === "supplier" ? data?.supplier?.id : data?.client?.id}
        close={() => toggleDrawer("right2", false)}
        type={type}
      />
    </>
  );
};
export default InventoryGenericDetailsView;
