import React, { useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import "../../../scss/adminBusiness.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValBusinessSocialMedia, valSchBusinessSocialMedia } from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import { BusinessMetaData } from '../../../api/businessManages';
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import { useLocation } from "react-router-dom";
const BUSINESSMETADATA = 1;

const SocialMedia = ({ data, UpdateInfo }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  // eslint-disable-next-line no-unused-vars
  const [msg, sendNotification] = useNotification();
  const location = useLocation();
  const isLandingRoute = location.pathname.includes("landing"); // Verifica si la ruta actual incluye "landing" exclusiva para el keskicreator


  const UpdateSocialMedia = async (id, formData) => {
    let updateSocialMedia = new BusinessMetaData();
    const response = await updateSocialMedia.editDataAuth(id, formData, logout);
    sendNotification(UseerrorOrsuccess(response))
    UpdateInfo();
  }



  const formik = useFormik({
    initialValues: initValBusinessSocialMedia(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBusinessSocialMedia()),
    onSubmit: async (formData) => {

      setLoading(true);

      UpdateSocialMedia(BUSINESSMETADATA, formData);

      setLoading(false);

      console.table(formData)

    },
  });




  return (
    <> {/* Paper className="paper" */}

      <form onSubmit={formik.handleSubmit} >
        <Grid container sx={{ padding: '16px' }}>
          <Grid item xs={12}>
            {isLandingRoute && (
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: "25px" }}>
                Redes sociales
              </Typography>
            )}
            <Box sx={{ mt: '1em' }}>
              <Typography variant="h6" gutterBottom component="div">
                <strong>Facebook</strong>
              </Typography>
              <Box sx={{ display: "flex", gap: "8px", marginTop: "8px", flexDirection: "column", justifyContent: "right", alignItems: "end" }}>
                <TextField
                  name="facebook"
                  label="Facebook"
                  placeholder="https://www.facebook.com/Ejemplo"
                  size="small"
                  fullWidth
                  value={formik.values.facebook}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.facebook && Boolean(formik.errors.facebook)
                  }

                  helperText={
                    formik.touched.facebook ? formik.errors.facebook : ""
                  }
                />


                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                    padding: 1,
                  }}
                >

                  {formik.values.facebook !== "" &&
                    <IconButton
                      color="gray"
                      onClick={() => {
                        formik.setFieldValue("facebook", "");
                        formik.handleSubmit();
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  }

                  {formik.values.facebook !== "" &&
                    <a href={formik.values.facebook} target="_blank">
                      <LoadingButton
                        variant="outlined"
                        color="primary"
                        component="span"
                        sx={{ borderRadius: '1em' }}
                      >
                        Probar enlace
                      </LoadingButton>
                    </a>}

                </Box>


              </Box>
            </Box>

            <Divider style={{ marginTop: '1em' }} />

            <Box sx={{ mt: '1em' }}>
              <Typography variant="h6" gutterBottom component="div">
                <strong>Instagram</strong>
              </Typography>
              <Box sx={{ display: "flex", gap: "8px", marginTop: "10px", flexDirection: "column", justifyContent: "right", alignItems: "end" }}>
                <TextField
                  name="instagram"
                  label="Instagram"
                  size="small"
                  fullWidth
                  placeholder="https://www.instagram.com/Ejemplo"
                  value={formik.values.instagram}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.instagram && Boolean(formik.errors.instagram)
                  }

                  helperText={
                    formik.touched.instagram ? formik.errors.instagram : ""
                  }
                />

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                    padding: 1,
                  }}
                >

                  {formik.values.instagram !== "" &&
                    <IconButton
                      color="gray"
                      onClick={() => {
                        formik.setFieldValue("instagram", "");
                        formik.handleSubmit();
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  }



                  {formik.values.instagram !== "" &&
                    <a href={formik.values.instagram} target="_blank" rel="noreferrer">
                      <LoadingButton
                        variant="outlined"
                        color="primary"
                        component="span"
                        sx={{ borderRadius: '1em' }}
                      >
                        Probar enlace
                      </LoadingButton>
                    </a>}
                </Box>


              </Box>
            </Box>

            <Divider style={{ marginTop: '1em' }} />

            <Box sx={{ mt: '1em' }}>
              <Typography variant="h6" gutterBottom component="div">
                <strong>Youtube</strong>
              </Typography>
              <Box sx={{ display: "flex", gap: "8px", marginTop: "8px", flexDirection: "column", justifyContent: "right", alignItems: "end" }}>
                <TextField
                  name="youtube"
                  label="Youtube"
                  size="small"
                  fullWidth
                  placeholder="https://www.youtube.com/Ejemplo"
                  value={formik.values.youtube}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.youtube && Boolean(formik.errors.youtube)
                  }

                  helperText={
                    formik.touched.youtube ? formik.errors.youtube : ""
                  }
                />

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                    padding: 1,
                  }}
                >

                  {formik.values.youtube !== "" &&
                    <IconButton
                      color="gray"
                      onClick={() => {
                        formik.setFieldValue("youtube", "");
                        formik.handleSubmit();
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  }



                  {formik.values.youtube !== "" &&
                    <a href={formik.values.youtube} target="_blank" rel="noreferrer">
                      <LoadingButton
                        variant="outlined"
                        color="primary"
                        component="span"
                        sx={{ borderRadius: '1em' }}
                      >
                        Probar enlace
                      </LoadingButton>
                    </a>}
                </Box>


              </Box>
            </Box>

            <Divider style={{ marginTop: '1em' }} />

            <Box sx={{ mt: '1em' }}>

              <Typography variant="h6" gutterBottom component="div">
                <strong>WhatsApp</strong>
              </Typography>
              <Box sx={{ display: "flex", gap: "8px", marginTop: "8px", flexDirection: "column", justifyContent: "right", alignItems: "end" }}>
                <TextField
                  name="whatsapp"
                  label="Whatsapp"
                  fullWidth
                  size="small"
                  placeholder="https://www.whatsapp.com/Ejemplo"
                  value={formik.values.whatsapp}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.whatsapp && Boolean(formik.errors.whatsapp)
                  }
                  helperText={
                    formik.touched.whatsapp ? formik.errors.whatsapp : ""
                  }
                />

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                    padding: 1,
                  }}
                >

                  {formik.values.whatsapp !== "" &&
                    <IconButton
                      color="gray"
                      onClick={() => {
                        formik.setFieldValue("whatsapp", "");
                        formik.handleSubmit();
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  }


                  {formik.values.whatsapp !== "" &&
                    <a href={formik.values.whatsapp} target="_blank" rel="noreferrer">
                      <LoadingButton
                        variant="outlined"
                        color="primary"
                        component="span"
                        sx={{ borderRadius: '1em' }}
                      >
                        Probar enlace
                      </LoadingButton>
                    </a>}
                </Box>
              </Box>
            </Box>

            <Divider style={{ marginTop: '1em' }} />

            <Box sx={{ mt: '1em' }}>

              <Typography variant="h6" gutterBottom component="div">
                <strong>Linkedin</strong>
              </Typography>
              <Box sx={{ display: "flex", gap: "8px", marginTop: "8px", flexDirection: "column", justifyContent: "right", alignItems: "end" }}>
                <TextField
                  name="linkedin"
                  label="Linkedin"
                  fullWidth
                  size="small"
                  placeholder="https://www.Linkedin.com/Ejemplo"
                  value={formik.values.linkedin}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.linkedin && Boolean(formik.errors.linkedin)
                  }
                  helperText={
                    formik.touched.linkedin ? formik.errors.linkedin : ""
                  }
                />

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                    padding: 1,
                  }}
                >

                  {formik.values.linkedin !== "" &&
                    <IconButton
                      color="gray"
                      onClick={() => {
                        formik.setFieldValue("linkedin", "");
                        formik.handleSubmit();
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  }


                  {formik.values.linkedin !== "" &&
                    <a href={formik.values.linkedin} target="_blank" rel="noreferrer">
                      <LoadingButton
                        variant="outlined"
                        color="primary"
                        component="span"
                        sx={{ borderRadius: '1em' }}
                      >
                        Probar enlace
                      </LoadingButton>
                    </a>}
                </Box>
              </Box>
            </Box>

            <Divider style={{ marginTop: '1em' }} />


            <Box sx={{ mt: '1em' }}>

              <Typography variant="h6" gutterBottom component="div">
                <strong>TikTok</strong>
              </Typography>
              <Box sx={{ display: "flex", gap: "8px", marginTop: "8px", flexDirection: "column", justifyContent: "right", alignItems: "end" }}>
                <TextField
                  name="tiktok"
                  label="TikTok"
                  fullWidth
                  size="small"
                  placeholder="https://www.TikTok.com/Ejemplo"
                  value={formik.values.tiktok}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.tiktok && Boolean(formik.errors.tiktok)
                  }
                  helperText={
                    formik.touched.tiktok ? formik.errors.tiktok : ""
                  }
                />

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                    padding: 1,
                  }}
                >

                  {formik.values.tiktok !== "" &&
                    <IconButton
                      color="gray"
                      onClick={() => {
                        formik.setFieldValue("tiktok", "");
                        formik.handleSubmit();
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  }


                  {formik.values.tiktok !== "" &&
                    <a href={formik.values.tiktok} target="_blank" rel="noreferrer">
                      <LoadingButton
                        variant="outlined"
                        color="primary"
                        component="span"
                        sx={{ borderRadius: '1em' }}
                      >
                        Probar enlace
                      </LoadingButton>
                    </a>}
                </Box>
              </Box>
            </Box>

            <Divider style={{ marginTop: '1em' }} />


          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              position: "sticky",
              bottom: 0,
              width: "100%",
              padding: "1em",
              backgroundColor: "white",
              zIndex: 1000,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Grid container direction="row" spacing={1} sx={{ justifyContent: "flex-end", alignItems: "center", mt: '1em', }}>
              <Grid>

                <LoadingButton
                  className='button'
                  type="submit"
                  disabled={!formik.dirty}
                  loading={loading}
                  variant="contained"
                  sx={{ marginRight: 2, color: "white", borderRadius: "15px" }}
                >
                  Actualizar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
export default SocialMedia;