import React, { useState, useEffect } from "react";
import {
  Typography,
  MenuItem,
  Box,
  Button,
  Menu,
} from "@mui/material";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../../hooks/snackBarResponse";
import Stack from "@mui/material/Stack";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { DateFormatter } from "../../../admin/adminBusiness";
import { ProductModel } from "../../../../api/productModel";

export function State(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [msg, sendNotification] = useNotification();
  const open = Boolean(anchorEl);
  const presentationId = props.presentationId;
  const matchId = Array.isArray(props.article) // Si `props.article` es un array
    ? props.article.find(
      (item) => String(item.id) === String(presentationId)
    )
    : undefined;

  const Productview =
    (props.model === 'model' || props.model === 'product')
      ? (props.article?.is_active ?? false)
      : (matchId?.is_active ?? false);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const EditProductModel = (formData) => {
    (async () => {
      const service = new ProductModel();

      let response = await service.editDataAuth(
        props.article.id,
        formData,
        props.logout
      );
      handleClose();

      if (response) {
        sendNotification(UseerrorOrsuccess(response));
      } else {
        sendNotification(
          UseerrorOrsuccess({ msg: "Error al actualizar", variant: "error" })
        );
      }
      props.getArticleInfo();
    })();
  };
  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        <RemoveRedEyeOutlinedIcon />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Typography mr={2}>Visibilidad: </Typography>
          <Typography>
            <strong>{Productview ? "Visible" : "Oculto"}</strong>
          </Typography>
        </Box>

        <Button
          variant="text"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{ textTransform: "none", fontSize: "16px" }}
          disabled={props.disabled || props.article?.toggle_active === false}
        >
          Editar
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >

          {[
            { label: "Visible", icon: <RemoveRedEyeOutlinedIcon sx={{ mr: 2 }} />, state: true },
            { label: "Ocultar", icon: <VisibilityOffOutlinedIcon sx={{ mr: 2 }} />, state: false }
          ].map((data, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                props.model === "model"
                  ? EditProductModel({ is_active: data.state })
                  : props.model === "presentation"
                    ? props.SetProductInfo(presentationId, { is_active: data.state })
                    : props.SetProductInfo({ is_active: data.state });
                handleClose();
              }}
            >
              <div style={{ display: "flex" }}>
                {data.icon}
                <Typography>{data.label}</Typography>
              </div>
            </MenuItem>
          ))}

        </Menu>
      </Stack>{" "}
      <Stack
        alignItems={"center"}
        direction={"row"}
        spacing={2}
        display={props.model !== "product" ? "none" : ""}
      >
        <CalendarMonthOutlinedIcon />
        <Typography p={"8px 0"}>
          Publicado:{" "}
          <b style={{ padding: "0 16px" }}>
            {DateFormatter(props.article?.date_created)}
          </b>
        </Typography>
      </Stack>
    </>
  );
}