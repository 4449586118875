import React, { useState, useEffect } from "react";
import BasicModal from "../../../components/GenericModal";
import { TextField, Grid, FormControl, FormHelperText, Typography, IconButton, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValAddSale, valSchAddSale } from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import FormControlLabel from "@mui/material/FormControlLabel";
import SalesService from "../../../api/salesService";
import CountWords from "../../../components/CountWords";
import { useNavigate } from "react-router-dom";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import WarehouseService from "../../../api/warehouseService";
import { formatISO } from "date-fns";
import { ConfirmAction } from "../../../components/GenericsModals";
import Button from '@mui/material/Button'; // Importa Button desde @mui/material
import { GenericDrawer } from "../../../components/keskiCreator/genericDrawer";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";


const AddSale = (props) => {
  const [loading, setLoading] = useState(false);
  const [warehouseList, setWarehouseList] = useState("");
  const [value, setValue] = useState(new Date("2014-08-18T21:11:54"));
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [msg, sendNotification] = useNotification();
  /* const [noOrder, setNoOrder] = useState(""); */


  //This state is to open confirmDialog
  const [openDialog, setOpenDialog] = useState(false);
  const warehouseService = new WarehouseService();


  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  useEffect(() => {
    warehouseListRequest();

  }, []);

  const warehouseListRequest = () => {
    (async () => {
      let irequestFilter = []
      irequestFilter.push({ 'key': 'get_main', 'value': `true` })
      const response = await warehouseService.filter(irequestFilter, logout);
      setWarehouseList(response.data);
      console.log("warehouse", response.data);
    })();
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const formik = useFormik({
    initialValues: initValAddSale(),
    validationSchema: Yup.object(valSchAddSale()),
    onSubmit: async (formData) => {
      console.log(formData);
      setLoading(true);
      formData.no_order = formData.no_order + props.idOrder.toString();
      formData.date_created = formatISO(formData.date_created);


      let salesSer = new SalesService();
      let response = await salesSer.postDataAuth(formData, logout);
      formik.resetForm();
      if (Number(response.status) < 300) {
        props.close();
        window.localStorage.setItem("menu", 19);
        sendNotification(UseerrorOrsuccess(response));
        navigate(`detail/${response.data.id}`);
      } else {
        sendNotification(UseerrorOrsuccess(response));
        props.close();
      }
      props.UpdateSalesList();
      setLoading(false);
    },
  });
  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [props.open]);




  return (
    <>
      <GenericDrawer
        open={props.open}
        toggleDrawer={props.toggleDrawer}
        anchor={"right"}
        drawerWidth="30%"
      >
        <Box p='16px'>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pb: "24px",
            }}
          >
            <Typography fontSize={"20px"} p={"8px 0"}>
              <b>Agregar venta</b>
            </Typography>

            <IconButton onClick={props.close}>
              <CloseSharpIcon fontSize="small" />
            </IconButton>
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid pt={'24px'} >
              <form onSubmit={formik.handleSubmit}>
                <Grid container rowSpacing={"8px"}>
                  <Grid display='flex' justifyContent={'space-between'} sx={{ width: '100%' }}>
                    <Grid item xs={7}>
                      <TextField
                        fullWidth
                        name="no_order"
                        value={formik.values.no_order}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.no_order && Boolean(formik.errors.no_order)
                        }
                        label="Numero de orden"
                        /*  margin="normal" */
                        size="small"
                        variant="outlined"
                        autoComplete="none"
                        helperText={
                          formik.touched.no_order ? formik.errors.no_order : ""
                        }
                        inputProps={{ maxLength: 20 - props.idOrder.toString().length }}
                      />
                      {props.idOrder && <CountWords
                        length={formik.values.no_order.length}
                        max={20 - props.idOrder.toString().length}
                      />}
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        autoComplete="none"
                        size="small"
                        id="no_order"
                        label="Número Consecutivo"
                        value={props.idOrder}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      renderInput={(props) => (
                        <TextField fullWidth size="small" {...props} />
                      )}
                      label="Fecha"
                      value={formik.values.date_created}
                      onChange={(newValue) => {
                        formik.setFieldValue("date_created", newValue);
                      }}
                      helpertext={formik.errors.date_created}
                      format="dd/MM/yyyy"
                    />

                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" size="small" >
                      <InputLabel id="demo-simple-select-filled-label">
                        Almacén
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        name="warehouse"
                        label="Almacén"
                        value={formik.values.warehouse}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.category &&
                          Boolean(formik.errors.warehouse)
                        } >
                        {warehouseList && warehouseList.map((data) => (
                          <MenuItem key={data.id} value={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {formik.touched.warehouse ? formik.errors.warehouse : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    Los artículos agregados a la orden se irán descontando del
                    inventario y cambiaran al estado de ordenados.
                  </Grid>
                  <Grid container justifyContent="flex-end">
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={6}
                      lg={6}
                      xl={6}
                      sx={{ marginTop: "15px" }}
                    >
                      <LoadingButton
                        loading={loading}
                        fullWidth
                        type="submit"
                        variant="contained"
                        sx={{ color: "#fff", borderRadius: 60 }}
                      >
                        Agregar
                      </LoadingButton>
                      <>
                      </>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </LocalizationProvider>
        </Box>
      </GenericDrawer>
    </>
  );
};

export default AddSale;
