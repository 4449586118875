import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { useAuth } from "user-context-fran-dev";
import { Button, Grid, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import suppliersImages from "../../assets/suppliers";

const GenericSearch = ({ Service, setData, label, setLoading, updateInfo }) => {
  const [searchString, setSearchString] = useState("");
  const [search, setSearch] = useState(false);

  const handleSearchChange = async () => {
    setLoading(true);
    try {
      let irequestFilter = [{ key: "pattern", value: `${searchString}` }];
      let serviceInstance = new Service();
      let response = await serviceInstance.filter(irequestFilter, useAuth);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      updateInfo();
    } finally {
      setLoading(false);
      setSearch(true)
    }
  };

  const handleClearSearch = () => {
    setSearchString(""); // Resetea el estado
    updateInfo()
  };

  return (
    <Grid container display="flex" alignItems="center">
      <FormControl
        variant="standard"
        sx={{ margin: "18px 0", minWidth: 220, width: "30%" }}
      >
        <TextField
          label={label}
          variant={"outlined"}
          size="small"
          value={searchString}
          onChange={(e) => setSearchString(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSearchChange();
          }}
        />
      </FormControl>
      <Grid
        item
        xs={0.5}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          onClick={handleClearSearch}
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            padding: 0,
          }}
        >
          <img
            src={suppliersImages.erase}
            alt="Clear search"
            style={{ width: "20px", height: "23px" }}
          />
        </button>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="outlined"
          sx={{
            borderRadius: "40px",
            width: "124px",
            fontSize: "14px",
            padding: "8px 10px",
          }}
          onClick={() => handleSearchChange()}
        >
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
};

export default GenericSearch;

export const GenericSearchDrawer = ({
  Service,
  setData,
  label,
  placeholder,
  setLoading,
}) => {
  const [searchString, setSearchString] = useState("");

  const handleSearchChange = (event) => {
    if (event.target.value.length >= 3 || event.target.value.length === 0) {
      (async () => {
        setSearchString(event.target.value);
        let irequestFilter = [];
        irequestFilter.push({ key: "pattern", value: `${event.target.value}` });
        let serviceInstance = new Service();
        let response = await serviceInstance.filter(irequestFilter, useAuth);
        setData(response.data);
      })();
    }
  };

  return (
    <FormControl
      fullWidth
      variant="standard"
      sx={{ minWidth: 220 }}
      value={searchString}
    >
      <TextField
        fullWidth
        name="search"
        //variant="outlined"
        //label={label}
        placeholder={placeholder}
        onChange={handleSearchChange}
        sx={{ backgroundColor: "white", borderRadius: "5px" }}
        margin="dense"
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export const GenericSearchNew = ({ params, setParams, handleChanges, handleSearch, label, name, width }) => {
  // Local state to control the value of the TextField
  const [inputValue, setInputValue] = useState(params.get(name) || "");

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value); // Update local state on input change
    handleChanges(event); // Call the provided handleChanges function (if needed)
  };

  const handleClearSearch = () => {
    setInputValue(""); // Reset the input field to empty
    // Optionally, you can reset any search parameters in URL
    if (params.has(name)) {
      params.delete(name); // Remove the search parameter
      setParams(params); // Update the URL parameters
    }
  };

  return (
    <Grid container display="flex" alignItems="center">
      <FormControl
        fullWidth name={name}
        sx={{ margin: "18px 0", minWidth: 220, width: width ?? "30%" }}>
        <TextField
          name={name}
          label={label}
          variant="outlined"
          size="small"
          value={inputValue || ''} // Bind to local state
          onChange={handleInputChange} // Handle change via local state
        />
      </FormControl>
      <Grid
        item
        xs={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          onClick={handleClearSearch}
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            padding: 0,
          }}
        >
          <img
            src={suppliersImages.erase}
            alt="Clear search"
            style={{ width: "20px", height: "23px" }}
          />
        </button>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="outlined"
          sx={{
            borderRadius: "40px",
            width: "124px",
            fontSize: "14px",
            padding: "8px 10px",
          }}
          onClick={handleSearch}
        >
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
};

