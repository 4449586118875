import { BASE_PATH } from "../../utils/constants";
import { BaseService } from 'base-service-fran-dev';
import { BASE_PATH_DASH } from "../../utils/constants";


export class SectionsCreator extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'landing/section';
    super(baseUrl, endPoint)
  }
}

export class SectionsCreatorWithIA extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'landing/build/view';
    super(baseUrl, endPoint)
  }
}

export class ImageCreator extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'landing/image/upload';
    super(baseUrl, endPoint)
  }
}


export class ImageCreatorDelete extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'landing/image';
    super(baseUrl, endPoint)
  }
}

export class Unsplash extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH_DASH;
    const endPoint = "integrations/unsplash/search";
    super(baseUrl, endPoint);
  }
}


export class SubcomponentCreator extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'landing/subcomponent';
    super(baseUrl, endPoint)
  }
}


export class ComponentCreator extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'landing/component';
    super(baseUrl, endPoint)
  }
}