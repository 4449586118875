import React, { useState, useEffect } from "react";
import {
  Avatar,
  FormControlLabel,
  InputAdornment,
  Paper,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import { TextField, Grid } from "@mui/material";
import "../scss/adminBusiness.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initPaymentConditions,
  valPaymentConditions,
} from "../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import BusinessManages from "../api/businessManages";
import Link from "@mui/material/Link";
import GenericPaymentConditions from "../components/GenericPaymentConditions";
import { Suppliers } from "../api/supplierService";
import CustomerService from "../api/customerService";
import { UseerrorOrsuccess, useNotification } from "../hooks/snackBarResponse";
import CurrencyFormat from "react-currency-format";
import { formatNumber } from "../utils/functions";

const PaymentConditions = ({ data, UpdateInfo, target }) => {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();

  var updateService = null;

  const UpdateProfile = async (id, formData) => {
    console.log(id);
    if (target === "supplier") {
      updateService = new Suppliers();
    } else if (target === "client") {
      updateService = new CustomerService();
    } else if (target === "business") {
      updateService = new BusinessManages();
    }
    const response = await updateService.editDataAuth(id, formData, logout);
    console.log("estoy aqui");
    UpdateInfo();
    if (response) {
      sendNotification(UseerrorOrsuccess(response));
    } else {
      sendNotification(UseerrorOrsuccess(response));
    }
  };

  const formik = useFormik({
    initialValues: initPaymentConditions(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valPaymentConditions()),
    onSubmit: async (formData) => {
      console.log(formData);

      setLoading(true);

      UpdateProfile(data.id, formData);

      setLoading(false);
    },
  });

  return (
    <Paper className="paper">
      <Typography variant="h6" gutterBottom component="div">
        <strong>Condiciones de pago</strong>
      </Typography>
      <Link
        component="button"
        variant="subtitle2"
        onClick={() => {
          setOpen(true);
        }}
        underline="none"
        className="link"
      >
        Editar
      </Link>
      <GenericPaymentConditions
        data={data}
        open={open}
        setOpen={setOpen}
        UpdateInfo={UpdateInfo}
        target={target}
      />
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="credit_days"
              label="Días de crédito"
              margin="dense"
              size="small"
              type="number"
              value={formik.values.credit_days}
              onChange={formik.handleChange}
              error={
                formik.touched.credit_days && Boolean(formik.errors.credit_days)
              }
              helperText={
                formik.touched.credit_days ? formik.errors.credit_days : ""
              }
              InputProps={{
                readOnly: true,
              }}
            />

            <TextField
              fullWidth
              name="discount"
              label="% descuento"
              margin="dense"
              size="small"
              value={formik.values.discount + "%"}
              onChange={formik.handleChange}
              error={formik.touched.discount && Boolean(formik.errors.discount)}
              helperText={formik.touched.discount ? formik.errors.discount : ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={0} md={0.2} />
          <Grid item xs={12} md={5.9}>
            <TextField
              fullWidth
              name="credit_limit"
              label="Limite de Crédito"
              margin="dense"
              size="small"
              value={formatNumber(formik.values.credit_limit, 2)}
              onChange={formik.handleChange}
              error={
                formik.touched.credit_limit &&
                Boolean(formik.errors.credit_limit)
              }
              helperText={
                formik.touched.credit_limit ? formik.errors.credit_limit : ""
              }
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default PaymentConditions;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#60269E",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#60269E",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

export const PaymentConditionsForm = ({ data, UpdateInfo, target }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [modified, setModified] = useState(false);
  const [msg, sendNotification] = useNotification();

  var updateService = null;

  const UpdateProfile = async (id, formData) => {
    if (target === "supplier") {
      updateService = new Suppliers();
    } else if (target === "client") {
      updateService = new CustomerService();
    } else if (target === "business") {
      updateService = new BusinessManages();
    }
    const response = await updateService.editDataAuth(id, formData, logout);
    UpdateInfo();
    if (response) {
      sendNotification(UseerrorOrsuccess(response));
    } else {
      sendNotification(UseerrorOrsuccess(response));
    }
  };

  const formik = useFormik({
    initialValues: initPaymentConditions(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valPaymentConditions()),
    onSubmit: async (formData) => {
      setLoading(true);

      UpdateProfile(data.id, formData);
      setModified(false)
      setLoading(false);
    },
  });

  return (
    <Grid >

      <form
        onSubmit={formik.handleSubmit}
        style={{ width: "100%" }}
        onChange={() => {
          setModified(true);
        }}>
        <Grid container xs={12} rowGap={2}>
          <Grid item xs={12}>
            <Grid container xs={12} justifyContent="space-between">
              <Grid item xs={5.5}>
                <TextField
                  fullWidth
                  name="credit_days"
                  label="Días de crédito"
                  margin="dense"
                  size="small"
                  type="number"
                  value={formik.values.credit_days}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.credit_days &&
                    Boolean(formik.errors.credit_days)
                  }
                  helperText={
                    formik.touched.credit_days
                      ? formik.errors.credit_days
                      : ""
                  }

                />
              </Grid>
              <Grid item xs={5.5}>
                <TextField
                  fullWidth
                  name="credit_limit"
                  label="Limite de Crédito"
                  margin="dense"
                  size="small"
                  value={formik.values.credit_limit}
                  onChange={formik.handleChange}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                    },
                  }}
                  error={
                    formik.touched.credit_limit &&
                    Boolean(formik.errors.credit_limit)
                  }
                  helperText={
                    formik.touched.credit_limit
                      ? formik.errors.credit_limit
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container xs={12} alignItems={"center"}>
              <Grid item xs={2}>
                <Typography
                  variant="p"
                  gutterBottom
                  className="title-card"
                  sx={{ padding: "0", color: "#605D5D", marginBottom: "0" }}
                >
                  <strong>Descuento</strong>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={formik.values.is_discount}
                      onChange={(e) => formik.setFieldValue("is_discount", e.target.checked)}
                      sx={{ m: 1 }} defaultChecked />}
                />
              </Grid>
            </Grid>
            <TextField
              fullWidth
              required={formik.values.is_discount}
              disabled={!formik.values.is_discount}
              name="discount"
              label="% descuento"
              margin="dense"
              size="small"
              value={formik.values.discount}
              onChange={formik.handleChange}
              error={
                formik.touched.discount && Boolean(formik.errors.discount)
              }
              helperText={
                formik.touched.discount ? formik.errors.discount : ""
              }
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <LoadingButton
              disabled={!modified}
              className="button"
              type="submit"
              sx={{
                backgroundColor: "#60269E",
                borderRadius: "50px",
              }}
              loading={loading}
              variant="contained"
            >
              Actualizar
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
