import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Logo from "../assets/LogoKeski";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { TapPlans } from "./InfoPlans";
import { Container, IconButton } from "@mui/material";
import { GenericDrawer } from "./keskiCreator/genericDrawer";
// import {
//   GenericDrawerScroll,
// } from "../../GenericCompnts/GenericDrawer";

const style = {
  width: "100%",
  bgcolor: "background.paper",
};

export default function BasicModal(props) {
  const { openDrawerPlans, toggleDrawerPlans, close } = props;

  return (
    <div>
      <GenericDrawer
        open={openDrawerPlans}
        toggleDrawer={toggleDrawerPlans}
        anchor="top"
        drawerWidth="100%"
      >
        <Box sx={style}>
          <Container maxWidth={"lg"}>
            <Grid
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              p={"20px 0"}
            >
              <Logo width={"150px"} height={"45px"} />
              <IconButton onClick={close}>
                <CloseSharpIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Box sx={{ mb: 3, pt: 3 }}>
              <TapPlans display={"block"} />
            </Box>
          </Container>
        </Box>
      </GenericDrawer>
    </div>
  );
}
