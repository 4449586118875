import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  styled,
  Paper,
  Stack,
  TextField,
  Box,
  Divider,
  IconButton,
} from "@mui/material";
import {
  InventoryService,
  InventoryServiceSalesOrder,
} from "../../api/inventoryService";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../hooks/snackBarResponse";
import {
  initAddArticleToPurchaseOrder,
  valSchAddToPurchaseOrder,
} from "../../utils/formHelper";
import { GenericDrawer } from "../../components/keskiCreator/genericDrawer";
import { useParams } from "react-router-dom";
import { useAuth } from "user-context-fran-dev";
import { useFormik } from "formik";
import * as Yup from "yup";
import ImageSize from "../ImageSize";
import { LoadingButton } from "@mui/lab";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Adjustment from "../../pages/inventory/operations/adjustment/adjustment";
import { AdjustmentService } from "../../api/inventory/adjustmentService";
import { TransferItemsService } from "../../api/inventory/transfersService";
import ScrapService, { ScrapServiceItems } from "../../api/inventory/scrapService";
import { AvailableAdjustmentAdd } from "../../api/availablePresentation";

const Item = styled(Paper)(({ theme }) => ({
  margin: "16px 0",
}));

export default function DrawerArticle(props) {

  const { id } = useParams();

  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [msg, sendNotification] = useNotification();
  const labels = ["Ord:", "Dis:", "Res:"];
  const keys = ["ordered", "available", "reserved"];
  const order_data = labels.map((label, index) => ({
    label,
    key: keys[index],
  }));

  const FormData = [
    {
      filter: "cost",
      label: "Costo",
    },
    {
      filter: "quantity",
      label: "Cantidad",
    },
  ];
  const Form = ({ data }) => {
    const formik = useFormik({
      initialValues: initAddArticleToPurchaseOrder(
        props.dataProduct?.presentations ? data : props.dataProduct
      ),
      validationSchema: false,
      onSubmit: async (formData, { resetForm }) => {
        
        formik.values.presentation = data?.inventory[0].presentation;
        formik.values.model = data?.inventory[0].model;
        formik.values.warehouse = data?.inventory[0].warehouse
        
        resetForm();
        setIsSubmitting(true);

        const services = {
          'transfer_order': new TransferItemsService(),
          'scrap_order': new ScrapServiceItems(),
          'adjustment_order': new AvailableAdjustmentAdd(),
        };
        const service = services[props.type];
        const key = props.type
        formData[key] = Number(id);
        let response = ''
        
        
        response = await service.postDataAuth(formData, logout);
        
        if (Number(response.status) < 300) {
          sendNotification(UseerrorOrsuccess(response));
          
        } else {
          sendNotification(UseerrorOrsuccess(response));
        } 
        
        setLoading(false);
        setIsSubmitting(false);
        props.reloadDataOforder();
        props.close();
      },
    });

    return (
      <Stack
        component="form"
        direction={"row"}
        onSubmit={formik.handleSubmit}
        justifyContent={"space-between"}
        p={"8px"}
      >
        {FormData.map((field) => (
          <TextField
            key={field.label}
            label={field.label}
            variant="outlined"
            size="small"
            type={field.filter === "cost" ? "text" : "number"}
            name={field.filter}
            value={formik.values[field.filter] || ""}
            onChange={formik.handleChange}
            error={
              formik.touched[field.filter] &&
              Boolean(formik.errors[field.filter])
            }
            helperText={
              formik.touched[field.filter] && formik.errors[field.filter]
            }
            sx={{ width: "30%" }}
            slotProps={{
              ...(field.filter === "cost" && props.dataProduct?.presentations
                ? { readOnly: true}
                : { min: 1, step: 1,}), 
                
            }}
            
            required={field.filter !== "cost"}
          />
        ))}

        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          sx={{ borderRadius: "60px", textTransform: "none" }}
          disabled={isSubmitting || !data.is_active}
        >
          {props.dataProduct?.presentations ? "Agregar" : "Actualizar"}
        </LoadingButton>
      </Stack>
    );
  };
  const ProductAtribute = ({ presentation }) => {

    return (
      <>
        {presentation ? (
          <Item elevation={4}>
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              p={"8px 8px 0 8px"}
            >
              <Typography>
                <strong>{presentation?.name}</strong>
              </Typography>
              <Typography color={presentation?.is_active ? "green" : "red"}>
                {presentation?.is_active ? "Activo" : "Desactivado"}
              </Typography>
            </Stack>

            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              p={"8px"}
            >
              {order_data?.map((data) => (
                <Typography key={data?.key}>
                  {data?.label} {presentation.inventory[0][data?.key] ?? "0"}{" "}
                  {/* Usar nullish coalescing (??) en lugar de || para verificar solo null o undefined */}
                </Typography>
              ))}
            </Stack>

            <Divider />
            <Form data={presentation} />
          </Item>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No hay presentación disponible.
          </Typography>
        )}
      </>
    );
  };
  return (
    <GenericDrawer
      open={props.openDrawer}
      toggleDrawer={props.toggleDrawer}
      anchor={"right1"}
      drawerWidth="30%"
    >
      <Box p={"16px"}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"20px"} p={"8px 0"}>
            <b>
              {props.dataProduct?.presentations
                ? "Presentaciones"
                : "Editar producto"}
            </b>
          </Typography>

          <IconButton onClick={props.close}>
            <CloseSharpIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Grid display={"flex"} p={"16px 0"}>
          <ImageSize
            img={props.dataProduct?.model?.image?.url || ""}
            weight={2}
          />
          <div>
            <Typography>
              <strong>
                {props.dataProduct?.model?.product?.name
                  ? props.dataProduct?.model?.product?.name
                  : props.dataProduct?.product?.name}
              </strong>
            </Typography>
            <Typography color="gray">
              {props.dataProduct?.model?.name}
            </Typography>
          </div>
        </Grid>
        {props.dataProduct?.presentations ? (
          <>
            {(props.dataProduct?.presentations || []).map((val, index) => (
              <div key={index}>
                <ProductAtribute presentation={val} />
              </div>
            ))}
          </>
        ) : (
          <ProductAtribute presentation={props.dataProduct} />
        )}
      </Box>
    </GenericDrawer>
  );
}
