import { BASE_PATH } from "../../utils/constants";
import {BaseService} from 'base-service-fran-dev';


export class TransferService extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'inventory/transfer_order';
      super(baseUrl, endPoint)
    }
}

export class TransferItemsService extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/transferred';
    super(baseUrl, endPoint)
  }  
}

export class TrnsferStatusService extends BaseService{
  constructor(endPointApi){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/' + endPointApi;
    super(baseUrl, endPoint)
  }
}