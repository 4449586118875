import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Chip, CircularProgress } from "@mui/material";
import { DrawerSupplier } from "./inventario/DrawerSupplier";
import { useAuth } from "user-context-fran-dev";
import { GenericEmptyTable, GenericSkeletonTable } from "./GenericEmptyTable";
import { PaginationGeneric } from "react-generic-pagination";
import OptionButton from "./inventario/optionButton";

const columns = [
  { id: "business_name", label: "Nombre", minWidth: 170 },
  { id: "phone", label: "Teléfono", minWidth: 100 },
  {
    id: "email",
    label: "Correo electrónico",
    minWidth: 170,
  },
  {
    id: "type",
    label: "Tipo",
    minWidth: 170,
  },
  { id:"more" , label: "Más", minWidth: 100 },
];

export const SupplierType = {
  1: "Empresa",
  2: "Persona",
};

export const StickyHeadTable = ({ rows,UpdateInfo, sx, loading }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer sx={sx}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#605D5D",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && <GenericSkeletonTable colums={4}  />}
            {!loading && !rows && (
              <GenericEmptyTable  />
            )}
            {!loading && rows && rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <StickyHeadTableRow key={row.id} row={row} UpdateInfo={UpdateInfo} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
};

export const StickyHeadTableRow = ({ row ,UpdateInfo}) => {
  const [openDrawer, setOpenDrawer] = useState({});
  const { logout } = useAuth();

  const renderCellContent = (value, column) => {
    switch (column.id) {
      case "type":
        return (
          <Chip
            label={SupplierType[value]}
            variant="outlined"
            sx={{
              height: "25px",
              fontSize: "14px",
              color: "#605D5D",
            }}
          />
        );
      case "more":
        return (
         <OptionButton
                     menu={18}
                     id={row.id}
                     openDrawer={() => setOpenDrawer({ right2: true })}
                     UpdateInfo={UpdateInfo}
                     type={"supplier"}
                   />
        );
      default:
        return value;
    }
  };

  return (
    <>
      <TableRow
        role="checkbox"
        tabIndex={-1}
        key={row.code}
      >
        {columns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell
              key={column.id}
              align={column.align}
              sx={{
                fontSize: "16px",
                color: "#605D5D",
              }}
            >
              {renderCellContent(
                column.format && typeof value === "number"
                  ? column.format(value)
                  : value,
                column
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <DrawerSupplier
        open={openDrawer}
        toggleDrawer={() => {}}
        logout={logout}
        id={row.id}
        UpdateInfo={UpdateInfo}
        close={() => setOpenDrawer({ right2: false })}
        type={"supplier"}
      />
    </>
  );
};
