import { Box, Divider, FormControlLabel, Grid, IconButton, InputBase, Radio, RadioGroup, Stack, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useState, useEffect } from "react";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import { PaginationGeneric } from "react-generic-pagination";
import { useNavigate } from "react-router-dom";
import { useImages } from "./useImages";
import { ImageCreator } from "../../../api/landing/creatorManages";
import { useAuth } from "user-context-fran-dev";
import nofound from "../../../assets/no_found.png"
import { ConfirmAction } from "../../../components/ConfirmAction/ConfirmAction";
import ConfirmOperation from "../../../components/ConfirmAction/ConfirmOperation";


export const ImageContainer = ({
  images,
  savedImages = false,
  saveNewImage,
  deleteImage,
  searchMoreImages,
  savedPage,
  turnBusiness,
  totalSavedPages,
  savedPageSetter,
  randomPage,
  totalRandomPages,
  randomPageSetter,
  context,
  setImage,
  select,
  setSelect
}) => {

  //console.log("Recibi el context en imageContainer:", context)
  const [data, setData] = useState("");
  const { logout } = useAuth();

  const [search, setSearch] = useState("");
  useEffect(() => {
    // Actualizar el estado de búsqueda si turnBusiness cambia
    setSearch(turnBusiness ?? "");
  }, [turnBusiness]);

  const UpdateImage = async () => {
    let irequestFilter = [];
    {/*if(type){
        irequestFilter.push({ key: "type", value: `${type}` });
        }*/}
    let getDirec = new ImageCreator();
    let response = await getDirec.filter(irequestFilter, logout);
    setData(response.data);
  }

  useEffect(() => {
    UpdateImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (
    <>
      <div
        style={{
          height: "auto",
          minHeight: "300px",
          maxHeight: "80vh",
          overflowY: "auto",
          paddingBottom: "90px",
        }}
      >
        {!savedImages && (
          <Grid
            item
            xs={5}
            sx={{
              p: "2px 4px",
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              border: 1,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder=""
              inputProps={{ "aria-label": "search images" }}
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={() => searchMoreImages(search)}
            >
              <SearchIcon />
            </IconButton>
          </Grid>
        )}
        <Grid
          container
          xs={12}
          sx={{ marginTop: "0px" }}
          spacing={2}
        >

          {images && images?.results ? images?.results.map((image) => {
            const id = image.id;
            const url = image.urls?.regular ?? "";
            const imgName = image.alt_description ?? "Sin nombre"; const author = image.user?.name ?? "Sin autor";
            //const profileAuthor = image.user?.links?.html ?? " ";


            return (
              <ImageEdition
                key={id}
                id={id}
                url={url}
                author={author}
                imgName={imgName}
                //profileAuthor={profileAuthor}
                savedImages={savedImages}
                deleteImage={deleteImage}
                saveNewImage={saveNewImage}
              />
            );
          })
            : images?.map((image) => (
              <ImageEdition
                key={image.id}
                {...image}
                savedImages={savedImages}
                deleteImage={deleteImage}
                saveNewImage={saveNewImage}
                context={context}
                UpdateImage={UpdateImage}
                setImage={setImage}
                select={select}
                setSelect={setSelect}
                data={data}
              />
            ))}

        </Grid>

        {images && (
          savedImages ? (
            <PaginationGeneric
              className="paginationStyle"
              page={savedPage}
              total={totalSavedPages}
              pageSetter={savedPageSetter}
            />
          ) : (
            <PaginationGeneric
              className="paginationStyle"
              page={randomPage}
              total={totalRandomPages}
              pageSetter={randomPageSetter}
            />
          )
        )}


        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {context === 'Remplazar' && (
            <Button
              onClick={() => { setImage(select) }}
              variant="contained"
              size="small"
              sx={{ borderRadius: '1em', backgroundColor: "#60269E" }}
            >
              Usar imagen
            </Button>
          )}
        </Box>
        {!savedImages && (
          <>
            <Divider sx={{ m: "36px 0" }} />
            <Typography variant="caption" gutterBottom sx={{ display: 'block', color: '#808080' }}>
              Imágenes proporcionadas por Unsplash.
            </Typography>
          </>
        )}
      </div>
    </>

  );
};

export const ImageEdition = ({
  id,
  url,
  author,
  imgName,
  //profileAuthor,
  savedImages,
  deleteImage,
  saveNewImage,
  context,
  UpdateImage,
  setImage,
  select,
  setSelect,
  data,

}) => {


  // Estado para el modal
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmationData, setConfirmationData] = useState(null);


  // Función para abrir el modal de aviso
  const handleOpenDialog = (id) => {
    setConfirmationData(id);
    setOpenDialog(true);
  };

  // Función para cerrar el modal si le dan en cancelar
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Función para manejar la eliminación
  const handleDeleteImage = () => {
    deleteImage(confirmationData);
    setOpenDialog(false);
  };

  return (
    <Grid container spacing={2} item xs={12} sm={6} md={4} lg={3} xl={3}>
      <Grid item xl={12} >
        <Card sx={{ maxWidth: 355, boxShadow: 'none', position: 'relative' }}>
          {context === 'Remplazar' && (
            <RadioGroup
              sx={{ flexDirection: "row", justifyContent: "space-around" }}
              name="image-selection"
              value={select}
            >
              <CardActionArea
                onClick={() => setSelect(id)}
                sx={{
                  position: 'relative',
                  border: select === id ? '6px solid #642BA0 ' : '6px solid transparent',
                }}
              >
                <CardMedia
                  component="img"
                  height="310px"
                  image={url}
                  loading="lazy"
                  style={{
                    objectFit: 'cover',
                  }}
                />
                {select === id && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    }}
                  />
                )}
              </CardActionArea>
            </RadioGroup>
          )}
          {context !== 'Remplazar' && (
            <CardActionArea>
              <CardMedia
                component="img"
                height="310px"
                image={url || nofound}
                loading="lazy"
                style={{ objectFit: 'cover', cursor: 'default' }}
              />
            </CardActionArea>
          )}
          <CardActions>
            <Grid
              container
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '2px 0px',
              }}
            >
              <Grid item xs={8}>
                <Stack>
                  <Tooltip title={imgName ?? 'Sin nombre'} placement="top">
                    <Typography variant="body2">
                      {imgName?.length > 15 ? `${imgName.substring(0, 15)}...` : imgName ?? 'Sin nombre'}
                    </Typography>
                  </Tooltip>
                  <Typography variant="body2">
                    Autor:{' '}
                    <a href={url} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                      <Typography component="span" variant="body2" sx={{ fontWeight: 'bold', color: '#642BA0' }}>
                        {author ?? 'Sin autor'}
                      </Typography>
                    </a>
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={4} sx={{ textAlign: 'right' }}>
                {savedImages ? (
                  <>
                    <ConfirmOperation
                      open={openDialog}
                      onClose={handleCloseDialog}
                      title={`¿Estás seguro de que deseas eliminar esta imagen?`}
                      content={`Al eliminarla de tu galería, es posible que afectes algunas secciones de tu página que dependan de ella.`}
                      disagreeText="Cancelar"
                      agreeText="Eliminar"
                      onConfirm={handleDeleteImage}
                    />
                    <DeleteIcon
                      sx={{ width: '18px', cursor: 'pointer' }}
                      onClick={() => handleOpenDialog(id)}
                    />
                  </>
                ) : (
                  <FileUploadIcon
                    sx={{ width: '18px', cursor: 'pointer' }}
                    onClick={() => saveNewImage(url)}
                  />
                )}
              </Grid>
            </Grid>
          </CardActions>
        </Card>

      </Grid>
    </Grid>
  );
};
