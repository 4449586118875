import { BASE_PATH } from "../../utils/constants";
import {BaseService} from 'base-service-fran-dev';


export class AdjustmentService extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'inventory/adjustment_order';
      super(baseUrl, endPoint)
    }
}

export class AdjustmentServiceItems extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/adjustment';
    super(baseUrl, endPoint)
  }
}

export class AdjustementStatusService extends BaseService{
  constructor(endPointApi){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/' + endPointApi;
    super(baseUrl, endPoint)
  }
}




