import { useEffect, useRef, useState } from "react";
import { ImageCreator, ImageCreatorDelete, Unsplash } from "../../../api/landing/creatorManages";
import { useSnackbar } from "notistack";
import { LandingImages } from "../../../api/landing/homeLanding";
import { useAuth } from "user-context-fran-dev";
import { useNavigate } from "react-router-dom";
import { UseerrorOrsuccess, useNotification } from "../../../hooks/snackBarResponse";

export const useImages = ({ setValue, turnBusiness }) => {
  const [randomImages, setRandomImages] = useState([]);
  const [saveImages, setSaveImages] = useState([]);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const [msg, sendNotification] = useNotification();


  const imageCreator = new ImageCreator(); //GET y POST de imagenes
  const removeImage = new ImageCreatorDelete(); //DELETE de imagenes
  const unsplashImage = new Unsplash();//POST  para IMAGENES unsplash


  const images = new LandingImages();

  const [isUploading, setIsUploading] = useState(false);
  const fileInput = useRef();
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const maxSize = 32 * 1024 * 1024;

  //PARA IMAGENES GUARDADAS PAGE
  const [savedPage, setSavedPage] = useState(1);         // Página de imágenes guardadas
  const [totalSavedPages, setTotalSavedPages] = useState(1);   // Total de páginas de imágenes guardadas
  //PARA IMAGENES UNSPLASH PAGE
  const [randomPage, setRandomPage] = useState(1);       // Página de imágenes generadas
  const [totalRandomPages, setTotalRandomPages] = useState(1); // Total de páginas de imágenes generadas
  const [currentQuery, setCurrentQuery] = useState(turnBusiness || '');



  const totalPerPage = 12;
  const response = '';


  //IMAGENES GUARDADAS
  const getImagesSaved = async () => {
    let irequestFilter = [];
    irequestFilter.push({ key: "page", value: `${savedPage}` });
    irequestFilter.push({ key: "items", value: `${totalPerPage}` });
    const response = await imageCreator.filter(irequestFilter, logout);
    setSaveImages(response.data.data);
    setTotalSavedPages(response.data.pagination); // Total de páginas para imágenes guardadas
    console.log("valor de pagination despues:", response.data.pagination)
    console.log("valor de total despues:", response.data.total)
  };


  // Para recargar imágenes guardadas cuando cambia savedPage
  useEffect(() => {
    getImagesSaved();
  }, [savedPage]);

  const savedPageSetter = (savedPage) => {
    setSavedPage(savedPage);
  };

  //--------------------------UNSPLASH------------------------------//
  const getImagesGenerated = async (query, page = 1, per_page = totalPerPage) => {
    try {

      const response = await unsplashImage.postData({
        query: query,
        page: page,
        per_page: per_page
      });
      if (response.status === 200) {
        setRandomImages(response.data);
        setTotalRandomPages(response.data.total_pages); // Total de páginas para imágenes generadas
        console.log("valor de total_pages despues:", response.data.total_pages)
        console.log("valor de total despues:", response.data.total)
      }
    } catch (e) {
      console.error(e);
    }
  };

  const randomPageSetter = (randomPage) => {
    setRandomPage(randomPage);
  };


  // useEffect para manejar la búsqueda inicial cuando 'turnBusiness' cambia
  useEffect(() => {
    if (turnBusiness) {
      searchMoreImages(turnBusiness);
    }
  }, [turnBusiness]);

  // useEffect para manejar el cambio de página
  useEffect(() => {
    if (currentQuery) {
      getImagesGenerated(currentQuery, randomPage);
    }
  }, [randomPage, currentQuery]);




  const changeInput = async (e) => {
    const file = e.currentTarget.files;
    setIsUploading(true);
    try {
      if (file.length <= 1) {
        if (file[0].size > maxSize) {
          enqueueSnackbar(
            "La imagen es demasiado grande. El tamaño máximo permitido es de 32MB.",
            {
              autoHideDuration: 5000,
              variant: "warning",
            }
          );
        } else {
          var formData = new FormData();
          formData.append("imgUpload", file[0]);
          formData.append("type", 1);

          await imageCreator.uploadImg(formData, logout);
          getImagesSaved();
          setValue(0);
          setIsUploading(false);
        }
      } else {
        enqueueSnackbar("Maximo 1 imagen ", {
          autoHideDuration: 1000,
          variant: "warning",
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const deleteImage = async (id) => {
    try {
      await removeImage.deleteAuth(id, logout);
      getImagesSaved();
      sendNotification(UseerrorOrsuccess({ msg: 'Imagen eliminada', variant: 'success' }))
    } catch (e) {
      console.error(e);
    }
  };

  const saveNewImage = async (url) => {
    try {
      const response = await imageCreator.postDataAuth(
        { url, type: 2 },
        logout
      );
      getImagesSaved();
      if (response.status === 201) {
        setValue(0);
      }
    } catch (e) {
      console.error(e);
    }
  };
  // Función para realizar una nueva búsqueda
  const searchMoreImages = async (query) => {
    try {
      // Actualiza la búsqueda actual y resetea la página a 1
      setCurrentQuery(query);
      setRandomPage(1);
      getImagesGenerated(query, 1);
      setRandomImages([]); // Limpia las imágenes anteriores

      const per_page = 12;
      const response = await unsplashImage.postData({
        query: query,
        page: 1, // Siempre empieza en la página 1 para nuevas búsquedas
        per_page: per_page,
      });

      if (response.status === 200) {
        setRandomImages(response.data);
        setTotalRandomPages(response.data.total_pages);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    randomImages,
    saveImages,
    changeInput,
    isUploading,
    fileInput,
    deleteImage,
    saveNewImage,
    searchMoreImages,
    savedPage,
    setSavedPage,
    totalSavedPages,
    savedPageSetter,
    randomPage,
    setRandomPage,
    totalRandomPages,
    randomPageSetter,
  };
};
