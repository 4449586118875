import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";

const ConfirmOperation = ({
    open,
    onClose,
    title,
    content,
    disagreeText = "Cancelar",
    agreeText = "Aceptar",
    onConfirm, //el onConfirm recibe la funcion que disparara de  eliminar
}) => {

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    variant="outlined"
                    sx={{ borderRadius: '1em', backgroundColor: "#fffff" }}
                >
                    {disagreeText}
                </Button>
                <Button
                    onClick={onConfirm} autoFocus
                    variant="contained"
                    sx={{ borderRadius: '1em', backgroundColor: "#60269E" }}
                >
                    {agreeText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmOperation;