import React, { useEffect } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  Container,
  Grid,

  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,

} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { GenericDrawer } from './genericDrawer';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import LinksNavbar from './LinksNavbar';
import logo from '../../assets/joe_keski_alt.jpeg';


function CreatorAppBar({ text, backGround, getEndpoint }) { // funcion para actulizar el json va a pasr a linksappbar
  const navigate = useNavigate();
  const NAV_ITEMS = [
    { tag: "TAG_STORE", title: "Productos", link: "/landing/store", submenu: true, display: true },
    { tag: "TAG_ABOUT", title: "Nosotros", link: "/landing/about", display: true },
    { tag: "TAG_CATALOG", title: "Catálogo", link: "/landing/catalog", submenu: true, display: true },
    { tag: "TAG_SERVI", title: "Servicios", link: "/landing/services", display: true },
    { tag: "TAG_LOCATION", title: "Location", link: "/landing/location", display: true },
  ];

  const filteredNavItemsResponsive = NAV_ITEMS.slice(0, -1);
  const [openDrawer, setOpenDrawer] = React.useState({
    //drawer links
    top: false,
    //drawer categories
    top1: false,
    //cart preview
    right: false,
    //profile drawer
    right1: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }
    setOpenDrawer({ ...openDrawer, [anchor]: open });
  };
  const handleNavigation = (link) => {
    navigate(link); // Redirige dinámicamente a la ruta proporcionada
  };

  const filteredNavItems = NAV_ITEMS.filter(
    (item) =>
      (item.tag === "TAG_LOCATION" ||
        item.tag === "TAG_PROFILE" ||
        item.tag === "TAG_CART") &&
      item.display
  );

  //Recibimos el endpoint actual
  const currentEndpoint = getEndpoint() || 'default/endpoint';

  useEffect(() => {
  }, [currentEndpoint]);

  const drawer = (
    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: 'row',
          alignItems: "center",
          justifyContent: "space-between",
          margin: "15px",
        }}
      >
        <IconButton id="button_close" sx={{ m: -1, p: -1 }} onClick={toggleDrawer("top", false)}>
          <CloseIcon sx={{ mt: 1 }} />
        </IconButton>
      </Box>
      <List>
        {filteredNavItemsResponsive.map((item, index) => (
          <ListItem key={index} >
            <ListItemButton sx={{ textAlign: "left" }} onClick={() => handleNavigation(item.link)}  >
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );


  return (
    <Box>
      <AppBar
        component="nav"
        sx={{
          backgroundColor: '#fff',
          maxWidth: "100vw",
          borderTop: 60,
        }}
      >
        <Toolbar
          sx={{
            margin: 0,
            "&.MuiToolbar-root": {
              padding: 0,
            },
          }}
        >
          <Container maxWidth="lg">
            <Toolbar
              sx={{
                display: { xs: "none", md: "flex" },
                margin: 0,
                "&.MuiToolbar-root": {
                  padding: 0,
                },
              }}
            >
              <Grid
                container
                sx={{ justifyContent: { xs: "space-between", } }}
              >
                <LinksNavbar
                  navItems={NAV_ITEMS}
                  text={'Black'}
                  icon={true}
                  getEndpoint={getEndpoint}
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                  }}
                />


              </Grid>
            </Toolbar>
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={logo}
                alt={`Logo cuadrado para sección de categorias de`}
                onClick={() => handleNavigation('/landing/root')}

                sx={{
                  height: "3vh",
                  width: "auto",
                  cursor: 'pointer',

                  // width: { xs: '100%', md: '30%' },
                  // aspectRatio: '1/1',
                  maxWidth: "50%",
                }}
              />
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  width: "100%",
                  height: "56px",
                  justifyContent: "flex-end",
                }}
              >
                {filteredNavItems.map((item, index) => {
                  if (item.tag === "TAG_LOCATION") {
                    return (
                      <IconButton key={index} size="small" id="icon_location">
                        <RoomOutlinedIcon sx={{ width: 28, height: 28 }} />
                      </IconButton>
                    );
                  } else {
                    return null;
                  }
                })}
                <IconButton
                  aria-label="open drawer"
                  onClick={toggleDrawer("top", true)}
                  sx={{ display: { md: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Box>
          </Container>

        </Toolbar>
      </AppBar>
      <Box component="nav">
        <GenericDrawer
          variant="temporary"
          open={openDrawer}
          toggleDrawer={toggleDrawer}
          anchor="top"
          drawerWidth="100%"
        >
          {drawer}
        </GenericDrawer>
      </Box>
    </Box>
  );
}

// // App title in appbar
// function TitlePage(props) {
//   const { navLanding, favicon } = props;


//   return (
//     <Link href={navLanding ? "/" : "/"}>
//       <Box
//         component="img"
//         alt="keski Nube icon"
//         src={favicon ? favicon : favicon}
//         sx={{ display: "flex", width: { xs: "120px", sm: "120px" } }}
//       />
//     </Link>
//   );
// }

export default CreatorAppBar;

