import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper, Box, styled, Divider } from "@mui/material";
import "../../../scss/catalog.scss";
import Products from "../../../api/productsManages";
import { ProductModelType } from "../../../api/productModel";
import { useAuth } from "user-context-fran-dev";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import {
  ArticleCategory,
  ArticleFilters,
  ArticleInfo,
  ArticleState,
  ArticleShowIn,
  ArticleType,
  ModelSimple,
  PriceForm,
  ArticleModel,
  Main_Model
} from "./components/zindex";
import { Filters } from "../../../api/catalog/filterManage";
import ArticleModels from "./articleModels";
import { parseWithOptions } from "date-fns/fp";
import EditIcon from "@mui/icons-material/Edit";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  margin: "0 0 24px",
  width: "100%",
}));
const scroll = {
  overflowY: "scroll",
  maxHeight: "300px",
  scrollbarWidth: "thin",
};

const ArticleData = ({ article, getArticleInfo, load }) => {
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const [selectedValue, setSelectedValue] = useState();
  const prop_path = ["cost", "price", "price_discount", "bar_code"];
  const mainid = article?.main_model?.id;
  const type = article.model ? article.type : selectedValue;

  const SetProductInfo = (formData) => {
    (async () => {
      const service = new Products();
      let response = await service.editDataAuth(article.id, formData, logout);
      if (response) {
        sendNotification(UseerrorOrsuccess(response));
      } else {
        sendNotification(
          UseerrorOrsuccess({ msg: "Error al actualizar", variant: "error" })
        );
      }
      getArticleInfo();
    })();
  };

  const ProductTypeService = (data) => {
    (async () => {
      const service = new ProductModelType();

      let response = await service.postDataAuth(
        {
          type: selectedValue,
          data: {
            product: article.id,
            name: data

          },
        },
        logout
      );

      if (response) {
        sendNotification(UseerrorOrsuccess(response));
      } else {
        sendNotification(
          UseerrorOrsuccess({ msg: "Error al actualizar", variant: "error" })
        );
      }
      getArticleInfo();
    })();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography fontSize={"24px"} margin={"24px 0 40px"}>
        <strong>Producto</strong>
      </Typography>
      <Grid
        container
        spacing={{ xs: 0, sm: 0, md: 3 }}
        display={"flex"}
        flexDirection={{
          md: "row",
          sm: "column-reverse",
          xs: "column-reverse",
        }}
      >
        {/* -------------Left Grid ------------------>*/}
        <Grid item xs={12} sm={12} md={8}>
          <ArticleInfo
            article={article}
            SetProductInfo={SetProductInfo}
            load={load}
          />
          <Card title="Tipo de producto">
            <ArticleType
              productType={ProductTypeService}
              article={article}
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
            />
          </Card>
          <Box>
            {(() => {
              switch (type) {
                case 1:
                  return (
                    <div style={{ display: article.model ? '' : 'none' }}>
                      <ModelSimple
                        productImage={
                          <ArticleModels
                            article={article.model ? article?.model[0] : article}
                            getArticleInfo={getArticleInfo}
                            load={load}
                          />
                        }
                        priceContent={
                          <PriceForm
                            data={article}
                            SetProductInfo={SetProductInfo}
                            logout={logout}
                            getArticleInfo={getArticleInfo}
                            modelId={mainid}
                            variantId={
                              Array.isArray(article?.model) && article.model[0]?.presentation?.length > 0
                                ? article.model[0].presentation[0]?.id
                                : null
                            }
                            simple={true}
                          />
                        }
                      />
                    </div>
                  );
                case 2:
                  return (
                    <ArticleModel
                      article={article}
                      logout={logout}
                      getArticleInfo={getArticleInfo}
                      productType={ProductTypeService}
                      productImage={
                        <ArticleModels
                          getArticleInfo={getArticleInfo}
                          load={load}
                        />
                      }
                      state={
                        <ArticleState
                          logout={logout}
                          getArticleInfo={getArticleInfo}
                        />
                      }
                      priceContent={
                        <PriceForm
                          prop_path={prop_path}
                          SetProductInfo={SetProductInfo}
                          logout={logout}
                          getArticleInfo={getArticleInfo}
                          type={article.type}
                        />
                      }
                    />
                  );
                case 3:
                  return (
                    <ArticleModel
                      article={article}
                      getArticleInfo={getArticleInfo}
                      productType={ProductTypeService}
                      productImage={
                        <ArticleModels
                          article={article}
                          getArticleInfo={getArticleInfo}
                          load={load}
                        />
                      }
                      state={
                        <ArticleState
                          logout={logout}
                          getArticleInfo={getArticleInfo}
                        />
                      }
                      priceContent={
                        <PriceForm
                          article={article}
                          SetProductInfo={SetProductInfo}
                          logout={logout}
                          getArticleInfo={getArticleInfo}
                          type={article.type}
                        />
                      }
                      variant={true}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </Box>
        </Grid>
        {/*--------------------- right grid -------------------->*/}
        <Grid item xs={12} sm={12} md={4}>
          <Card title={"Estado"}>
            <ArticleState
              article={article}
              SetProductInfo={SetProductInfo}
              model={"product"}
            />
          </Card>
          <Card title={"Mostrar producto"}>
            <ArticleShowIn article={article} SetProductInfo={SetProductInfo} />
          </Card>


          <Grid display={article.model && article.type === 3 || article.type === 2 ? 'block' : 'none'}>
            <Card title={"Modelo principal"}>
              <Main_Model
                article={article}
                SetProductInfo={SetProductInfo}
                logout={logout}
              />
            </Card>
          </Grid>
          <Card p="8px 16px" title={"Categorías"} style={scroll}>
            <ArticleCategory
              SetProductInfo={SetProductInfo}
              article={article}
              logout={logout}
            />
          </Card>
          <Card title={"Filtros"} style={scroll}>
            <ArticleFilters articl={article} getArticleInfo={getArticleInfo} />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ArticleData;

export function Card(props) {
  return (
    <Item elevation={4}>
      <Grid sx={{ bgcolor: "#f7f7f7", display: 'flex', justifyContent: 'space-between' }}>
        <Typography p={props.padding || "16px"} fontSize={props.fontSize} color="#605D5D">
          <strong>{props.title}</strong>
        </Typography>
        {props.icon}
      </Grid>
      <Divider />
      <Grid
        p={props.p ? props.p : "16px"}
        sx={props.style}
        height={props.height}
        display={props.display}
        flexDirection={props.direction}
        alignItems={props.align}
      >
        {props.children}
      </Grid>
    </Item>
  );
}
