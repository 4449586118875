import React, { useState } from "react";
import { IconButton, CardHeader } from "@mui/material";
import { Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TableInfo from './tableInfo';
import CurrencyFormat from 'react-currency-format';
import { DateFormat } from '../../../components/DateFormat'
import { STATUSSALES, STATUSSALESCOLOR } from '../../../utils/constants'
import Chip from '@mui/material/Chip';
import { DrawerSupplier } from "../../../components/inventario/DrawerSupplier";
import OptionButton from "../../../components/inventario/optionButton";

const SalesTable = (props) => {
  const { data, UpdateInfo } = props
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState({});
  const navigate = useNavigate();

  const beginDate = data.date_created

  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 }, }}
      >
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw" }}>
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {data.no_order}
              </Typography>}
            subheader={DateFormat({ beginDate })}
          />
        </TableCell>
        <TableCell sx={{ minWidth: "20px", maxWidth: "15vw" }} align="left">
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography variant="subtitle1" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {data.client?.business_name}
              </Typography>}
            subheader={data.warehouse.name}
          />
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw" }}>
          <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Chip label={STATUSSALES[data.status]} color={STATUSSALESCOLOR[data.status]} className="chip-with" variant="outlined" />
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw" }}>
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography variant="subtitle1" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <CurrencyFormat value={data.total} displayType={'text'} thousandSeparator={true} prefix={'$'} />
              </Typography>}
          />

        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw" }}>
          <OptionButton
            menu={19}
            id={data.id}
            openDrawer={() => setOpenDrawer({ right2: true })}
            UpdateInfo={UpdateInfo}
            type={"sales"}
          />
        </TableCell>

      </TableRow>

      <DrawerSupplier
        open={openDrawer}
        toggleDrawer={() => { }}
        id={data.id}
        UpdateInfo={UpdateInfo}
        close={() => setOpenDrawer({ right2: false })}
        type={"sales"}
      />
    </>
  );
}

export default SalesTable;