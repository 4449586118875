import React, { useState } from "react";
import { Avatar, Paper, Typography } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import "../scss/adminBusiness.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValBusinessProfile,
  valSchBusinessProfile,
} from "../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import { Suppliers } from "../api/supplierService";
import CustomerService from "../api/customerService";
import { ApartmentIcon } from "@mui/icons-material/Apartment";
import { UseerrorOrsuccess, useNotification } from "../hooks/snackBarResponse";

const GenericProfile = ({ data, UpdateInfo, target }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();

  let updateService = null;

  const UpdateProfile = async (id, formData) => {
    console.log(id);
    if (target == "supplier") {
      updateService = new Suppliers();
    } else if (target == "client") {
      updateService = new CustomerService();
    }
    const response = await updateService.editDataAuth(id, formData, logout);
    if (Number(response.status) < 300) {
      sendNotification(UseerrorOrsuccess(response));
    }
    UpdateInfo();
  };

  const formik = useFormik({
    initialValues: initValBusinessProfile(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBusinessProfile()),
    onSubmit: async (formData) => {
      formik.values.business_name = formik.values.name;
      console.log(formData);

      setLoading(true);

      UpdateProfile(data.id, formData);

      setLoading(false);
    },
  });

  return (
    <Paper className="paper">
      <Typography variant="h6" gutterBottom component="div">
        <strong>Perfil</strong>
      </Typography>
      <Avatar className="avatar" alt="Remy Sharp" />
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="name"
              label="Nombre de la empresa"
              margin="dense"
              size="small"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name ? formik.errors.name : ""}
            />
            <TextField
              fullWidth
              name="phone"
              label="Teléfono"
              margin="dense"
              size="small"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone ? formik.errors.phone : ""}
            />
            <TextField
              fullWidth
              name="email"
              label="Correo electronico"
              margin="dense"
              size="small"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email ? formik.errors.email : ""}
            />
          </Grid>
          <Grid item xs={0} sm={8} md={9} lg={10} />
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <br />
            <LoadingButton
              fullWidth
              disabled={!(formik.isValid && formik.dirty)}
              className="button"
              type="submit"
              loading={loading}
              variant="contained"
            >
              Actualizar
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default GenericProfile;

export const ProfileForm = ({ data, UpdateInfo, target }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const [modified, setModified] = useState(false);

  let updateService = null;

  const UpdateProfile = async (id, formData) => {
    console.log(id);
    if (target == "supplier") {
      updateService = new Suppliers();
    } else if (target == "client") {
      updateService = new CustomerService();
    }
    const response = await updateService.editDataAuth(id, formData, logout);
    if (Number(response.status) < 300) {
      sendNotification(UseerrorOrsuccess(response));
    }
    UpdateInfo();
  };

  const formik = useFormik({
    initialValues: initValBusinessProfile(data),
    enableReinitialize: true,
    validationSchema: false,
    onSubmit: async (formData) => {
      formik.values.business_name = formik.values.name;
      console.log(formData);

      setLoading(true);

      UpdateProfile(data.id, formData);

      setLoading(false);
      setModified(false);
    },
  });

  return (


    <Grid item xs={12} >
      <form
        onSubmit={formik.handleSubmit}
        onChange={() => {
          setModified(true);
        }}
      >
        <Grid container xs={12} rowGap={"20px"}>
          <Grid item xs={12}>
            <Grid container rowGap={"20px"}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  name="name"
                  label="Nombre de la empresa"
                  margin="dense"
                  size="small"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name ? formik.errors.name : ""}
                />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <TextField
                  fullWidth
                  name="phone"
                  label="Teléfono"
                  margin="dense"
                  size="small"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone ? formik.errors.phone : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="email"
                  label="Correo electronico"
                  margin="dense"
                  size="small"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email ? formik.errors.email : ""}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <LoadingButton
              disabled={!modified}
              className="button"
              type="submit"
              sx={{
                backgroundColor: "#60269E",
                borderRadius: "50px",
              }}
              loading={loading}
              variant="contained"
            >
              Actualizar
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Grid>

  );
};
