import React, { useState, useEffect } from "react";
import BasicModal from "./GenericModal";
import Typography from "@mui/material/Typography";
import { TextField, Grid, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initComment, valComment } from "../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import CommentService from "../api/commentService";
import { useParams } from 'react-router-dom'
import { UseerrorOrsuccess, useNotification } from '../hooks/snackBarResponse'
import CountWords from "./CountWords";
import { GenericDrawer } from "./keskiCreator/genericDrawer";
import CloseIcon from '@mui/icons-material/Close';



const GenericComments = ({ setOpen, open, UpdateInfo, data, business, target }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const { id } = useParams()
  const [msg, sendNotification] = useNotification();
  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });

  const toggleDrawer = (anchor, open) => {
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open,
    }));
    formik.resetForm();
  };


  const UpdateComment = async (id, formData) => {

    let updateComment = new CommentService();
    const response = await updateComment.postDataAuth(id, formData, logout);
    if (response?.data) {
      sendNotification(UseerrorOrsuccess(response))
    } else {
      sendNotification(UseerrorOrsuccess(response))
    }
    UpdateInfo();
  }
  const SetComment = async (formData) => {
    let updateComment = new CommentService();
    const response = await updateComment.postDataAuth(formData, logout);
    if (response?.data) {
      sendNotification(UseerrorOrsuccess(response))
    } else {
      sendNotification(UseerrorOrsuccess(response))
    }
    UpdateInfo();
  }

  const formik = useFormik({
    initialValues: initComment(),
    enableReinitialize: true,
    validationSchema: Yup.object(valComment()),
    onSubmit: async (formData, { resetForm }) => {
      const updatedData = { ...formData };

      switch (target) {
        case "supplier":
          updatedData.supplier = id;
          break;
        case "client":
          updatedData.client = id;
          break;
        case "purchase_order":
          updatedData.purchase_order = id;
          break;
        default:
          break;
      }

      setLoading(true);
      console.log(updatedData);

      try {
        await SetComment(updatedData);
        resetForm(); // Resetea el formulario
      } catch (error) {
        console.error("Error al guardar comentario:", error);
      } finally {
        setLoading(false);
        toggleDrawer("right", false);
      }
    },
  });



  return (
    <><Grid display={'flex'} justifyContent={'flex-start'}>
      <Button
        variant='text'
        sx={{ color: "#60269E", fontWeight: "600", p: 0 }}
        onClick={() => { toggleDrawer("right", true); }} underline="none" className="link">
        + Crear Comentario
      </Button>
    </Grid>
      <GenericDrawer
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        anchor={"right"}
        drawerWidth="30%"
      >
        <Grid
          p={"16px 24px"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Typography >
            <strong>Agregar comentarios</strong>
          </Typography>
          <CloseIcon
            sx={{ cursor: "pointer", }}
            onClick={() => toggleDrawer("right", false)}
          />
        </Grid>
        <form style={{ padding: '16px' }} onSubmit={formik.handleSubmit} className="comment">
          <Grid container>
            <Grid item xs={12}>
              <TextField
                inputProps={{ maxLength: 250 }}
                fullWidth
                label="Comentario"
                multiline
                maxRows={10}
                name="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={formik.touched.comments && Boolean(formik.errors.comments)}

                helperText={formik.touched.comments ? formik.errors.comments : ""}
              >
                <CountWords length={formik.values.comments.length} max="250" />
              </TextField>
            </Grid>
            <Grid item xs={0} md={.2} />
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid
              item
              xs={12}
              sm={7}
              md={6}
              lg={6}
              xl={6}
              sx={{ marginTop: "15px" }}
            >
              <LoadingButton
                loading={loading}
                fullWidth
                type="submit"
                variant="contained"
                sx={{ color: "#fff", borderRadius: 60 }}
              >
                Agregar
              </LoadingButton>
            </Grid>
          </Grid>

        </form>
      </GenericDrawer></>

  );
}

export default GenericComments;