import * as context from "user-context-fran-dev";
import { createContext, useEffect, useState, useMemo } from "react";
import jwtDecode from "jwt-decode";

export const AuthLoadingContext = createContext({
  loading: false,
  setLoading: () => null,
});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [online, setOnline] = useState(false);
  const [reloadUser, setReloadUser] = useState(false);
  const [loading, setLoading] = useState(true);

  const login = (data, user) => {
    context.login(data, user, setAuth);
  };

  const setBasePath = (basePathUrl) => {
    context.setBaseUrl(basePathUrl);
  };

  const logout = () => {
    context.logout(auth, setAuth);
    setReloadUser(true);
    // client.end()
  };

  function setCashRegister(cashRegister) {
    //console.log('set localstorage', JSON.stringify(cashRegister))
    localStorage.setItem("cashregister", JSON.stringify(cashRegister));
    setReloadUser(true);
  }

  const autData = useMemo(
    () => ({
      auth,
      login,
      setBasePath,
      logout,
      setReloadUser,
      setCashRegister,
      // mqttPublish,
      online,
    }),
    [auth, online]
  );

  useEffect(() => {
    setLoading(true);

    const token = context.getToken();
    const user = context.getUser();
    if (token) {
      setAuth({
        token,
        idUser: jwtDecode(token).id,
        user: user,
      });
    } else {
      setAuth(null);
    }
    setReloadUser(false);
    setLoading(false);
  }, [setReloadUser]);

  return (
    <context.AuthContext.Provider value={autData}>
      <AuthLoadingContext.Provider value={{ loading, setLoading }}>
        {children}
      </AuthLoadingContext.Provider>
    </context.AuthContext.Provider>
  );
};
