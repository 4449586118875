import { BASE_PATH } from "../utils/constants";
import {BaseService} from 'base-service-fran-dev';

export default class PurchaseService extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'inventory/purchase_order';
      super(baseUrl, endPoint)
    }
  }
  
export class PurchaseStatusService extends BaseService{
  constructor(endPointApi){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/' + endPointApi;
    super(baseUrl, endPoint)
  }
}

export class SalesStatusService extends BaseService{
  constructor(endPointApi){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/' + endPointApi;
    super(baseUrl, endPoint)
  }
}