import {Category} from "./articleCategory";
import {ProductFilters} from "./articleFilters";
import {ProductInfo} from "./articleInfo";
import {Simple ,Model} from "./articleModel";
import {ShowIn} from "./articleShowIn";
import {State} from "./articleState";
import {Type} from "./articleType";
import {PriceForm as priceForm} from "./priceForm";
import MainModel  from "./mainModel";

export const  ArticleCategory = Category;
export const  ArticleFilters = ProductFilters;
export const  ArticleInfo = ProductInfo;
export const  ArticleShowIn = ShowIn;
export const  ArticleState = State;
export const  ArticleType = Type;
export const  PriceForm = priceForm;
export const  ArticleModel = Model;
export const  ModelSimple = Simple;
export const  Main_Model = MainModel;


