import { BASE_PATH } from "../utils/constants";
import {BaseService} from 'base-service-fran-dev';

export  class LoginApi extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'auth/login/';
    super(baseUrl, endPoint)
  }
}

export  class RecoverPass extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'auth/password/reset';
    //BASE_PATH+endPoint
    //https://api.suajam.com/api/v1/auth/password/reset
    super(baseUrl, endPoint)
  }
}

export  class ResetPass extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'auth/password/reset/confirm';  
    super(baseUrl, endPoint)
  }
}




export  class ChangesPass extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'auth/password/change';  
    super(baseUrl, endPoint)
  }
}


/* export async function loginApi(formData){
  try {
    const url = `${BASE_PATH}/auth/login/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify(formData)
    }
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error)
    return null;
  }
}
 */

/* export async function addUser(formData){
  try {
    const url = `${BASE_PATH}/auth/registration`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify(formData)
    }
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error)
    return null;
  }
} */