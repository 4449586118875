import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../../hooks/snackBarResponse";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Filters, ProductsFilters } from "../../../../api/catalog/filterManage";
import { useParams } from "react-router-dom";

export function ProductFilters(props) {
  const productFeatureService = new ProductsFilters();
  const featureService = new Filters();
  const [msg, sendNotification] = useNotification();
  const { id } = useParams();
  const [expanded, setExpanded] = useState(false);
  const [showInput, setShowInput] = useState("");
  const [feature, setFeature] = useState("");
  const [featureId, setFeatureId] = useState(null);
  const [productFeature, setProductfeature] = useState();
  const [checked, setChecked] = useState(false);
  const [featureName, setFeatureName] = useState("");
  const [matchedId, setMatchedId] = useState(null);
  const handleAcordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false); // Abre el acordeón seleccionado o cierra si está ya abierto
  };

  useEffect(() => {
    FeatureServices();
    FeatureProductModelService();
  }, []);

  const FeatureServices = () => {
    (async () => {
      let response = await featureService.getAllAuth(props.logout);
      setFeature(response?.data);
    })();
  };

  const FeatureServicesPost = (name, featureID) => {
    (async () => {
      const response = await featureService.postDataAuth(
        {
          name: name,
          parent: featureID ? featureID : null, // Revisar lógica según tu intención
        },
        props.logout
      );
      setShowInput(" ")
      FeatureServices();
      if (response) {
        sendNotification(UseerrorOrsuccess(response));
      } else {
        sendNotification(
          UseerrorOrsuccess({ msg: "Error al actualizar", variant: "error" })
        );
      }
    })();
  };

  const FeatureProductModelService = () => {
    (async () => {
      try {
        const response = await productFeatureService.getAllAuth(props.logout);
        setProductfeature(response.data)
        const matchedItems = response.data.filter(
          (item) => String(item.product) === String(id)
        );
        // console.log("matchedItems:", matchedItems);
        const featureIds = matchedItems.map((item) => item.feature);
        setFeatureId(featureIds);
        console.log(featureIds);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    })();
  };

  const FeatureProductServicePost = async (ProductId, FilterId) => {
    try {
      const response2 = await productFeatureService.postDataAuth(
        {
          product: ProductId,
          feature: FilterId,
        },
        props.logout
      );
      console.log("Respuesta de productFeatureService:", response2);
      FeatureProductModelService();
    } catch (error) {
      console.error("Error al realizar las solicitudes:", error);
    }
  };
  const FeatureProductServiceDelete = async (FilterId) => {
    try {
      const matchId = productFeature.find(
        (item) => String(item.feature) === String(FilterId)
      );

      if (matchId) {
        const response = await productFeatureService.deleteAuth(
          matchId.id,
          props.logout
        );
        console.log("Respuesta de productFeatureService:", response);
        FeatureProductModelService();
      } else {
        console.log("No se encontró coincidencia");
      }
    } catch (error) {
      console.error("Error al realizar las solicitudes:", error);
    }
  };

  const handleCheckboxChange = async ({ e, FilterId }) => {
    const isChecked = e.target.checked;
    try {
      if (isChecked) {
        await FeatureProductServicePost(id, FilterId);
      } else {
        await FeatureProductServiceDelete(FilterId);
      }
    } catch (error) {
      console.error("Error processing checkbox action:", error);
    }
  };

  return (
    <>
      {feature &&
        feature.map((data, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleAcordion(index)}
            sx={{
              boxShadow: "none",
              border: "none",
              "&:before": {
                display: "none", // Remove the default divider line
              },
            }}
          >
            <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />}>
              <Stack direction={"column"}>
                <Typography>{data.name}</Typography>

                <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ gap: '5px' }}>
                  {data.list &&
                    data?.list.map((val, index) => (
                      <Chip sx={{
                        fontSize: "14px",
                        bgcolor: '#efe9f5',
                        color: '#60269E',
                        display: Array.isArray(featureId) && val.id && featureId.includes(val.id) ? '' : 'none'
                      }}
                        size="small"
                        label={val.name}
                        key={index} />
                    ))}
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ p: "0 16px" }}>
              {data.list &&
                data?.list.map((val, index) => (
                  <Stack key={index} direction="row" alignItems={"center"}>
                    <FormControlLabel control={
                      <Checkbox
                        checked={(featureId || []).includes(val.id)}
                        onChange={(e) => handleCheckboxChange({ e, FilterId: val.id })}
                      />
                    } />
                    <Typography>{val.name}</Typography>
                  </Stack>
                ))}
              <Grid
                sx={{
                  display: showInput === "genero" ? "flex" : "none",
                  alignItems: "center",
                }}
              >
                <FormControlLabel control={
                  <Checkbox
                    checked={true}
                  />}
                />
                <TextField
                  size="small"
                  margin="dense"
                  label={"Agregar genero"}
                  name="name"
                  value={featureName}
                  onChange={(e) => setFeatureName(e.target.value)}
                />
                <CloseIcon
                  sx={{
                    fontSize: 30,
                    m: "0 8px",
                    cursor: "pointer",
                    color: "GrayText",
                    ":hover": { color: "red" },
                  }}
                  onClick={() => setShowInput("")}
                />
                <IconButton
                  color="primary"
                  type="submit"
                  onClick={() => FeatureServicesPost(featureName, data.id)}
                >
                  <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />
                </IconButton>
              </Grid>
              <Button
                onClick={() => setShowInput("genero")}
                type="submit"
                variant="text"
                sx={{ pt: "16px" }}
              >
                + Añadir
              </Button>
            </AccordionDetails>
          </Accordion>
        ))}
      <Grid
        display={"flex"}
        //onSubmit={}
        sx={{
          display: showInput === "filter" ? "flex" : "none",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          margin="dense"
          label={"Agregar filtro"}
          name="name"
          value={featureName}
          onChange={(e) => {
            const { value } = e.target;
            setFeatureName(value);
          }}
          sx={{ mr: "16px" }}
        />
        <CloseIcon
          sx={{
            fontSize: 30,
            m: "0 8px",
            cursor: "pointer",
            color: "GrayText",
            ":hover": { color: "red" },
          }}
          onClick={() => setShowInput("")}
        />
        <IconButton
          color="primary"
          type="submit"
          onClick={() => FeatureServicesPost(featureName)}
        >
          <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </Grid>
      <Grid display={"flex"} justifyContent={"flex-start"}>
        <Button
          onClick={() => setShowInput("filter")}
          type="submit"
          variant="text"
          sx={{ p: "8px 0" }}
        >
          + Crear filtro
        </Button>
      </Grid>
    </>
  );
}