import React, { useEffect, useState } from 'react';
import { Box, Button, Collapse, Grid, Typography, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import BasicPopover from './GenericPopover';
import { useAuth } from 'user-context-fran-dev';
import { useNavigate } from 'react-router-dom';
import BusinessManages, { BusinessMetaData, BusinessSettings } from '../api/businessManages';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import { GenericDrawer } from './keskiCreator/genericDrawer';
import { ModalImages } from '../pages/landing/modalImages/modalImages';
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import MetaData from './landing/metaData';

const GenericPopoverResponsive = ({ title, children, menu, view  }) => {
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false);
    };


      //****************LandinMetaData********************** */

  const [business, setBusiness] = useState("");
  const [styles, setStyles] = useState("");
  const { logout } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [openImages, setOpenImages] = useState(false); // Nuevo estado para ModalImages
  const [modalContext, setModalContext] = useState(""); // Nueva bandera

  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });

  const toggleDrawer = (anchor, open) => {
    // console.log("Toggling drawer", anchor, open);
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    UpdateBusinessInfo();
    UpdateStyles();
    window.localStorage.setItem("menu", menu);
  }, []);

  const UpdateBusinessInfo = () => {
    (async () => {
      let useBusines = new BusinessMetaData();
      let response = await useBusines.getAllById("1");
      setBusiness(response.data);
    })();
  };

  const getTurnBusiness = async () => {
    try {
      let useBusiness = new BusinessManages();
      let response = await useBusiness.getAllAuth(logout);
      return response.data.turn_business;
    } catch (error) {
      console.error("Error al obtener el turn_business:", error);
      return null;
    }
  };

  const handleClickOpenModal = async () => {
    
    toggleDrawer("top", false);
    if (view === "Legal") {
      navigate('/landing/politics');
    } else if (view === "Libreria") {
      const turnBusiness = await getTurnBusiness();
      setBusiness((prev) => ({ ...prev, turn_business: turnBusiness }));
      setModalContext("Personalizar"); // Establecer el contexto
      setOpenImages(true);
      //console.log("Contexto en Landing:", modalContext)
    } else {
      toggleDrawer("right", true);  // Cierra el drawer derecho
      //setModalContext("Remplazar"); // Establecer el contexto
      //console.log("Contexto en Landing:", modalContext)
    }
  };

  useEffect(() => {
    console.log("ModalContext actualizado:", modalContext);
  }, [modalContext]);

  const UpdateStyles = () => {
    (async () => {
      let useBusines = new BusinessSettings();
      let response = await useBusines.getAllByIdAuth("1", logout);
      setStyles(response.data);
    })();
  };

  const handleCloseImages = () => {
    setOpenImages(false); // Cambia el estado a false para cerrar el modal
  };

  //************************************************** */
    return (
        <Box sx={{ width: '100%' }}>
            <Button
                fullWidth
                variant="text"
                onClick={handleToggle}
                sx={{
                    color: 'black',
                    justifyContent: 'space-between',
                    textTransform: 'none',
                    padding: 1,

                }}
            >
                {title}
            </Button>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft: 2,
                    }}
                >
                    
                    {title === 'Personalizar' ? (
                        <>
                            <BasicPopover
                                title={"Estilos"}
                                menu={2}
                                view={"Estilo"}
                                color={"black"}
                            />
                            <BasicPopover
                                title={"Librería"}
                                menu={2}
                                view={"Libreria"}
                                color={"black"}
                            />
                        </>
                    ) : title === 'Información' ? (
                        <>
                            <BasicPopover
                                title={"Información"}
                                menu={2}
                                view={"Información"}
                                color={"black"}
                            />
                            <BasicPopover
                                title={"Legal"}
                                menu={2}
                                view={"Legal"}
                                color={"black"}
                            />
                        </>
                    ) : null}
  
                    </Box>
            </Collapse>
            <GenericDrawer
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        anchor="right"
        drawerWidth="30%"
      >
        <Grid container sx={{ padding: "16px" }}>
        <Grid
          item xs={12}
          sx={{
            paddingTop: "20px",
            display: 'flex',
            justifyContent: 'space-between'
          }}

        >

          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            <strong>{title}</strong>
          </Typography>

          <IconButton onClick={() => toggleDrawer("right", false)}>
            <CloseSharpIcon fontSize="small" />
          </IconButton>
        </Grid>
        </Grid>
        {business && <MetaData data={business} update={UpdateBusinessInfo} updateStyles={UpdateStyles} styles={styles} view={view} />}
      </GenericDrawer>

      {view === "Libreria" && openImages && (
        <Grid item xs={12} mt="16px">
          <ModalImages
            data={business.library} // Asegúrate de que los datos estén disponibles
            turnBusiness={business.turn_business}
            UpdateInfo={UpdateBusinessInfo}
            open={openImages}
            onClose={handleCloseImages}
            context={modalContext} // Pasar el contexto
          />
        </Grid>

      )}

        </Box>
        
    );
};

export default GenericPopoverResponsive;