import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  MenuItem,
  TextField,
  Box,
  Button,
} from "@mui/material";
import Stack from "@mui/material/Stack";

export function Type(props) {
  const productTypes = ["Simple", "Con modelos", "Con modelos y variantes"];
  const [selectedOption, setSelectedOption] = useState("");
  const handleChange = (event) => {
    console.log(event.target.value);
    props.setSelectedValue(event.target.value); // Update the state with the selected value
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <Box
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ display: !props.article.model ? "flex" : "none" }}
      >
        <Stack direction={"column"} width={"100%"}>
          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <TextField
              size="small"
              margin="normal"
              label={"Tipo de producto"}
              select
              value={props.selectedValue}
              onChange={handleChange}
              sx={{ width: "40%" }}
            >
              {productTypes.map((val, index) => (
                <MenuItem key={index} value={index + 1}>
                  <Typography>{val}</Typography>
                </MenuItem>
              ))}
            </TextField>
            <Button
              variant={"contained"}
              onClick={() => {
                props.productType(null);
              }}
              sx={{
                height: "40px",
                width: "94px",
                borderRadius: 60,
                display: selectedOption === 1 ? "" : "none",
              }}
            >
              Crear
            </Button>
          </Grid>
        </Stack>
      </Box>
      <Typography
        display={props.article.model ? " " : "none"}
        variant="h4"
        fontSize={"24px"}
        p={"8px 0"}
      >
        {props.article.type === 1
          ? "Simple"
          : props.article.type === 2
            ? "Modelos"
            : "Modelos con variantes"}
      </Typography>
    </>
  );
}