
import axios from 'axios';
/* import { BASE_PATH } from './utils/constants';
import { useSnackbar } from 'notistack'; */
/* const {logout, auth}= useAuth(); */

const fetchData = axios.create();

axios.interceptors.request.use(
   (req) => {
      console.log(req)
      return req;
   },
   (err) => {

      console.log(err)
      return err;
   }
);

axios.interceptors.response.use(
   (req) => {
      //console.log(req)
      //console.log("error2")
      return req;
   },
   (err) => {
      if (err.response.status === 403) {
         console.log("forbiden")
         window.location = '/error'
         return Promise.reject(err);
      }
      if (err.response.status === 401) {
         localStorage.removeItem("token")
         window.location = '/'
         return Promise.reject(err);
      }

      console.log("error")
      console.log(err)
      return err;
   }
);

export default fetchData;