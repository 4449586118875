import React, { useState, useEffect } from "react";
import BasicModal from "../../../../components/GenericModal";
import { TextField, Grid, IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "user-context-fran-dev";
import { useNotification } from '../../../../hooks/snackBarResponse'
import { initAdjustment, ValAdjustment } from '../../../../utils/formHelper'
import { AdjustmentService } from "../../../../api/inventory/adjustmentService"
import Typography from "@mui/material/Typography";
import WarehouseService from "../../../../api/warehouseService";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from "react-router-dom";
import { GenericDrawer } from "../../../../components/keskiCreator/genericDrawer";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

const AddAdjustment = (props) => {

  const [loading, setLoading] = useState(false);
  const { logout, auth } = useAuth();
  const [warehouseList, setWarehouseList] = useState('')
  const navigate = useNavigate();

  useEffect(
    () => {
      warehouseListRequest()
    }, []
  );

  const warehouseListRequest = () => {
    (async () => {
      const warehouseService = new WarehouseService();
      const response = await warehouseService.getAllAuth(logout);
      setWarehouseList(response.data)
      console.log('warehouse', response.data)
    })();
  };

  const today = new Date();

  const formik = useFormik({
    initialValues: initAdjustment(auth.user.pk),
    validationSchema: Yup.object(ValAdjustment()),
    onSubmit: async (formData) => {
      setLoading(true);
      let adjustmentService = new AdjustmentService();
      let response = await adjustmentService.postDataAuth(formData, logout);
      /* console.log(response) */
      /* sendNotification(UseerrorOrsuccess(response.data)) */

      if (Number(response.status) < 300) {

        window.localStorage.setItem("menu", 21);
        navigate(`adjustment/${response.data.id}`);
        setLoading(false);
        props.close();
      }
      //props.UpdateAdjustment();  
      setLoading(false);
      props.close();
    },
  });

  return (
    <>
      <GenericDrawer
        open={props.open}
        toggleDrawer={props.toggleDrawer}
        anchor={"right"}
        drawerWidth="30%"
      >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: "16px",
          }}
        >
          <Typography fontSize={"20px"} p={"8px 0"}>
            <b>Agregar ajustes</b>
          </Typography>

          <IconButton onClick={props.close}>
            <CloseSharpIcon fontSize="small" />
          </IconButton>
        </Grid>
        <form
          style={{ padding: '16px' }}
          onSubmit={formik.handleSubmit}
          className="fullwith">
          <Grid item xs={12} >
            <Dia text={today} />
          </Grid>
          <Grid item xs={12} className="margin-scrap-table">
            <TextField
              fullWidth
              name="reference"
              label="Referencia de ajuste"
              margin="dense"
              size="small"
              value={formik.values.reference}
              onChange={formik.handleChange}
            />
          </Grid>
          <InputLabel className="select-warehouse" id="warehouse">Almacen</InputLabel>
          <Select
            fullWidth
            name="warehouse"
            id="warehouse"
            size="small"
            value={formik.values.warehouse}
            onChange={formik.handleChange}
            error={
              formik.touched.warehouse
            }
            helperText={
              formik.touched.warehouse ? formik.errors.warehouse : ""
            }
          >
            {warehouseList ? warehouseList.map((data, key) => (
              <MenuItem key={key} value={data.id}><a>{data.name}</a></MenuItem>
            )) : ''}
          </Select>
          <Grid item xs={12} className="margin-scrap-table">
            <p>
              Recuerda cerrar el ajuste de inventario al terminar de actualizar las cantidades para que se reflejen en el inventario.
            </p>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid
              item
              xs={12}
              sm={7}
              md={6}
              lg={6}
              xl={6}
              sx={{ marginTop: "15px" }}
            >
              <LoadingButton
                loading={loading}

                fullWidth
                type="submit"
                variant="contained"
                sx={{ color: "#fff" }}
              >
                Agregar
              </LoadingButton>
            </Grid>

          </Grid>
        </form>
      </GenericDrawer>

    </>
  )
}

const Dia = ({ text }) => {
  const Month = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const dia = new Date(text);
  return (
    <Typography variant="h7" component="div">
      {`Fecha: ${Month[dia.getMonth()]} ${dia.getDate()}, ${dia.getFullYear()}`}
    </Typography>
  );
};

export default AddAdjustment;

