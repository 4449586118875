import React, { useEffect, useState } from "react";
import Container from "../../layout/gridContainer";
import Articles from "./articles/articles";
import Products from "../../api/productsManages";
import { useParams } from "react-router-dom";
import { useAuth } from "user-context-fran-dev";

const CatalogArticles = () => {
const [articleInfo, setArticleInfo] = useState("");
const { id } = useParams();
const { logout } = useAuth();
const [loading, setLoading] = useState(true);

const getArticleInfo = () => {
    (async () => {
      setLoading(true);
      let getArticle = new Products();
      let response = await getArticle.getAllByIdAuth(id, logout);
      setArticleInfo(response.data);

      setLoading(false);
    })();
  };
    return ( 
    <Container>
        <Articles 
            article={articleInfo}
            getArticleInfo={getArticleInfo}
            load={loading}
        /> 
    </Container> 
    );
}
 
export default CatalogArticles;