import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Stack,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import "../../../scss/catalog.scss";
import PricePresentation from "./articlePricePresentation";
import AddModel from "./articleAddModel";
import { styled } from "@mui/material/styles";
import { ProductModelImageDelete } from "../../../api/productModel";
import { useAuth } from "user-context-fran-dev";
import { GenericHeaderTitle } from "../../../components/GenericHeader";
import CircularProgress from "@mui/material/CircularProgress";
import StarIcon from "@mui/icons-material/Star";
import { useParams } from "react-router-dom";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import Products from "../../../api/productsManages";
import { useSnackbar } from "notistack";
import CircleIcon from "@mui/icons-material/Circle";
import AddImage2 from "./addImage2";
import AddBoxIcon from '@mui/icons-material/Add';

const ArticleModels = ({ article, getArticleInfo, load }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const theme = useTheme();
  /* const classes = useStyles(); */
  const [model, setModel] = useState("");

  const handleAddModel = (data, edit) => {
    setModel({ ...data, isEdit: edit });
    setOpen(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Grid container >
      <Grid item xs={12}>
        <InfoModelo
          data={article}
          getArticleInfo={getArticleInfo}
          handleAddModel={handleAddModel}
        />
      </Grid>

      {!article && load && (
        <Grid container justifyContent="center" p={"32px"}>
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};
export const Input = styled("input")({
  display: "none",
});

const InfoModelo = ({ data, getArticleInfo, handleAddModel }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const { id } = useParams();

  const handleCloseFile = () => {
    setOpen(false);
  };

  const changeInput = (e) => {
    console.log(e.currentTarget.files);
    console.log(URL.createObjectURL(e.currentTarget.files[0]));
    if (e.currentTarget.files.length <= 1) {
      setFile(e.currentTarget.files[0]); //URL.createObjectURL()
      console.log(URL.createObjectURL(e.currentTarget.files[0]));
      setOpen(true);
      console.log("open");
    } else {
      console.log("Maximo 1 imagen");

      enqueueSnackbar("Maximo 1 imagen ", {
        autoHideDuration: 1000,
        variant: "warning",
      });
    }
  };

  const DeleteImgs = (id) => {
    (async () => {
      let images = new ProductModelImageDelete();
      await images.deleteAuth(id, logout);
      getArticleInfo();
    })();
  };

  return (
    <Grid container>
      <Grid item xs={6} sm={3}>
        {file && open && (
          <AddImage2
            open={open}
            handleClose={handleCloseFile}
            file={file}
            idModel={data.id}
            update={getArticleInfo}
          />
        )}
      </Grid>

      <Stack
        className="container-img"
        sx={{
          flexWrap: "no-wrap",
          overflowX: "auto",
          width: "100%",
          alignItems: "center",
        }}
        direction="row"
        justifyContent={!data.images ? "center" : ""}
      >
        {data.images &&
          data.images.map((data, index) => (
            <Paper sx={{ p: "8px" }} className="car-img" index={index}>
              <button
                className="close-button"
                onClick={() => DeleteImgs(data.id)}
              >
                {" "}
                x{" "}
              </button>
              <img
                alt="lost"
                src={data.url}
                className="img-size"
                loading="lazy"
              />
            </Paper>
          ))}
        <label >
          <Input hidden type="file" multiple onChange={changeInput}></Input>
          <Grid 
            alignItems={'center'} 
            justifyContent={'center'} 
            display={'flex'} 
            flexDirection={'column'}
            m={'16px 8px'}
            >
             
          <IconButton
            component="span"
            sx={{border:'2px solid grey', borderRadius:'10%', }}
            // onClick={handleClickSelectImage}
          >
            <AddBoxIcon  sx={{ fontSize: 40, border:'2px dashed grey' }} />
          </IconButton>
          <Typography p={'8px'} color='#605D5D'>
          Galeria del producto
          </Typography>
          </Grid>
        </label>
      </Stack>
    </Grid>
  );
};

export default ArticleModels;
