import { BASE_PATH, BASE_PATH_DASH } from "../utils/constants";
import { BaseService } from 'base-service-fran-dev';


export default class BusinessManages extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'site/business';
    super(baseUrl, endPoint)
  }
}


export class BusinessMetaData extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'site/business_metadata';
    super(baseUrl, endPoint)
  }
}

export class BusinessKeyword extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'site/keyword';
    super(baseUrl, endPoint)
  }
}


export class BusinessImages extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'site/business/image';
    super(baseUrl, endPoint)
  }
}

export class BusinessTypography extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'site/typography';
    super(baseUrl, endPoint)
  }
}

export class BusinessSettings extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'site/business_settings';
    super(baseUrl, endPoint)
  }
}
export class BusinessSettingsColors extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'site/colors_theme';
    super(baseUrl, endPoint)
  }
}

export class BusinessSubscription extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'site/business/subscription';
    super(baseUrl, endPoint)
  }
}


export class BusinessPlans extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH_DASH;
    const endpoint = "payments/plan/display";
    super(baseUrl, endpoint);
  }
}
